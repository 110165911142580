export enum ROUTES {
    ROOT = '/',
    SIGN_IN = '/sign-in',
    SIGN_UP = '/sign-up',
    MAP = '/map',
    CREATE_USER = '/create-user',
    AREAS = '/areas',
    SCENARIOS = '/scenarios',
    PROJECTS = '/projects',
    DASHBOARD = '/dashboard',
    ANALYTICS = '/analytics',
    SETTINGS = '/settings',
    HELP = '/help',

    TELEMARKETING = '/lead',

    ATLAS = '/atlas',

    UI = '/ui',
}
