import { Button } from 'components/Button';
import styles from '../styles.module.scss';
import { ButtonPalette, ButtonSize } from 'components/Button/Button';

export const Buttons = () => {
    return (
        <table className={styles.table}>
            <tr>
                <td>
                    size
                </td>
                <td>
                    palette
                </td>
                <td>
                    normal
                </td>
                <td>
                    loading
                </td>
                <td>
                    disabled
                </td>
            </tr>
            <tr>
                <td>Button - {ButtonSize.LG}<br />
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.PRIMARY}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.PRIMARY}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    {/* Button - {ButtonSize.LG}<br />
                    <code>
                        size=&quot;{ButtonSize.LG}&quot;
                    </code> */}
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.SECONDARY_COLOR}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.SECONDARY_COLOR}
                        size={ButtonSize.LG}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_COLOR}
                        size={ButtonSize.LG}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_COLOR}
                        size={ButtonSize.LG}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                    Button - {ButtonSize.SM}<br />
                    <code>
                        size=&quot;{ButtonSize.SM}&quot;
                    </code>
                </td>
                <td>
                    <code>
                        palette=&quot;{ButtonPalette.SECONDARY_GRAY}&quot;
                    </code>
                </td>
                <td>

                    <Button
                        palette={ButtonPalette.SECONDARY_GRAY}
                        size={ButtonSize.SM}
                    >
                        Text
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_GRAY}
                        size={ButtonSize.SM}
                        isLoading
                    >
                        isLoading
                    </Button>
                </td>
                <td>
                    <Button
                        palette={ButtonPalette.SECONDARY_GRAY}
                        size={ButtonSize.SM}
                        isDisabled
                    >
                        isDisabled
                    </Button>
                </td>
            </tr>
        </table>);
};