import * as yup from 'yup';

export enum CreateRegionInputs {
    NAME = 'name'
}

export enum KnockListInputs {
    NUMBER_SALES_PEOPLE = 'chunk_count',
    MIN_COUNT = 'min_property_count'
}

export enum MarketingInputs {
    max_property_count = 'max_property_count',
}

export const validationSchema = yup
    .object({
        name: yup.string().required('This field is required')
    });

export const defaultValuesCampaign = {
    name: ''
};

// knock list
export const validationSchemaKnockList = yup
    .object({
        chunk_count: yup
            .number()
            .min(1, 'Should be at least one person')
            .required('This field is required')
            .typeError('You must specify a number greater than 0'),
        min_property_count: yup
            .number()
            .min(160, 'Min value must be 160')
            .nullable()
            .notRequired()
            .typeError('You must specify a number equal or greater than 160'),
    });

export const defaultValuesKnockList = {
    chunk_count: 1,
    min_property_count: 160
};

// marketing
export const validationSchemaMarketing = yup
    .object({
        max_property_count: yup
            .number()
            .min(1, 'Should be at least one address')
            .required('This field is required')
            .typeError('You must specify a number greater than 1'),
    });

export const defaultValuesMarketing = {
    max_property_count: 1,
};