import { Chart } from 'elements/Chart';
import React from 'react';
interface Props {
    data: any;
}

export const PieChart: React.FC<Props> = ({ data }) => {
    const plotConfig = [
        {
            name: 'values',
            labels: data.labels,
            values: data.values,
            type: 'pie',
        }
    ];
    return <>
        <Chart
            plotData={plotConfig}
            style={{
                miHeight: '550px',
            }}
            layout={{
                font: {
                    color: '#020632'
                },

                legend: {
                    x: 0,
                    y: 1,
                    automargin: true,
                    textinfo: 'label',
                    xanchor: 'left',
                    orientation: 'v',
                }
            }}
        />
    </>;
};