import { Icons } from 'icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { closeDrawer, selectDrawer } from 'store/reducers/drawerSlice';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

interface Props {
    children?: any;
    title?: string;
    subtitle?: string;
}

export const Drawer: React.FC<Props> = ({ children, title, subtitle }) => {
    const drawer = useSelector(selectDrawer);
    const dispatch = useDispatch();

    return <div className={classNames(styles.drawer, {
        [styles.open]: drawer.isOpen
    })}>
        <div className={styles.drawerClose}
            onClick={() => {
                dispatch(closeDrawer());
            }}>
            <Icons.Close />
        </div>
        <div className={styles.drawerHeader}>
            <h3 className={classNames(
                'text-lg', 'text-500', 'text-primary'
            )}>{title}</h3>
            <p className={classNames('text-sm', 'text-secondary', 'text-400')}>{subtitle}</p>
        </div>
        <div className={styles.drawerBody}>
            {children}
        </div>
    </div>;
};