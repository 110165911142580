import { Table, Tag } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignDigitalMarketingRunAPI } from 'services/Campaigns/CampaignsDigitalMarketingRunService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { RunStatus } from '..';
import { nameFormatter } from '../Marketing';

export const DigitalMarketing = () => {
    const { uuid: campaignUuid } = useSelector(selectCampaign);

    const { data: runs = [], isLoading } = campaignDigitalMarketingRunAPI.useGetAllDigitalMarketingRunsQuery();
    const [runsInCampaign, setRunsInCampaign] = useState([]);

    useEffect(() => {
        if (runs.length) {
            const arrayOfRuns = runs.filter((el: any) => el.campaign_uuid === campaignUuid).map((el: any) => ({
                key: el.uuid,
                ...el
            }));
            setRunsInCampaign(arrayOfRuns);
        }

    }, [isLoading]);

    const columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (name: any) => {
                return nameFormatter(name);
            },
        },
        { title: 'Property count', dataIndex: 'max_property_count', },
        {
            title: 'Active', dataIndex: 'is_active',
            render: (is_active: any) => is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,

        },
        {
            title: 'Status', dataIndex: 'status', render: (status: any) => {

                switch (status) {
                    case RunStatus.SUCCESS:
                        return <Tag color='green'>{RunStatus.SUCCESS}</Tag>;
                    case RunStatus.FAILED:
                        return <Tag color='red'>{RunStatus.FAILED}</Tag>;
                    case RunStatus.RUNNING:
                        return <Tag color='yellow'>{RunStatus.RUNNING}</Tag>;
                    case RunStatus.PENDING:
                        return <Tag color='blue'>{RunStatus.PENDING}</Tag>;
                }
            },
        },
    ];

    return <>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Digital marketing runs</h2>

        <Table
            columns={columns}
            pagination={false}
            dataSource={runsInCampaign}
        />
    </>;
};