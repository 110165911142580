// @ts-nocheck
export const Positive = () => {
    return <><svg width="130" height="66" viewBox="0 0 130 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4992_376490" maskUnits="userSpaceOnUse" x="1" y="1" width="128" height="64">
            <rect width="128" height="64" transform="translate(1 1)" fill="url(#paint0_linear_4992_376490)" />
        </mask>
        <g mask="url(#mask0_4992_376490)">
            <path opacity="0.2" d="M5.60606 57.2759L1 65H129V1L127.182 12.0345H124.758C124.111 13.8736 122.77 17.5517 122.576 17.5517C122.382 17.5517 121.121 19.7586 120.515 20.8621L115.303 24.1724L112.273 28.5862L107.424 24.1724L104.879 20.8621L101.242 28.5862H97.4848L94.3333 20.8621C93.1212 19.7586 90.6485 17.5517 90.4545 17.5517C90.2606 17.5517 88.6768 15.3448 87.9091 14.2414H85.9697L81.3636 17.5517L77.3636 20.8621L75.5455 28.5862L70.0909 31.8966L67.303 35.2069L64.1515 40.7241L61.1212 47.9655H59.7879L56.1515 48.6552L52.6364 40.7241L49.6061 35.2069H47.7879L44.6364 31.8966L40.1515 35.2069H35.9091L31.6667 40.7241L27.4242 47.3448L24.2727 53.9655L20.8788 57.2759L16.2727 53.9655L12.6364 57.2759L9 60.5862L5.60606 57.2759Z" fill="#079455" />
        </g>
        <path d="M1 65L5.60606 57.2759L9 60.5862L12.6364 57.2759L16.2727 53.9655L20.8788 57.2759L24.2727 53.9655L27.4242 47.3448L31.6667 40.7241L35.9091 35.2069H40.1515L44.6364 31.8966L47.7879 35.2069H49.6061L52.6364 40.7241L56.1515 48.6552L59.7879 47.9655H61.1212L64.1515 40.7241L67.303 35.2069L70.0909 31.8966L75.5455 28.5862L77.3636 20.8621L81.3636 17.5517L85.9697 14.2414H87.9091C88.6768 15.3448 90.2606 17.5517 90.4545 17.5517C90.6485 17.5517 93.1212 19.7586 94.3333 20.8621L97.4848 28.5862H101.242L104.879 20.8621L107.424 24.1724L112.273 28.5862L115.303 24.1724L120.515 20.8621C121.121 19.7586 122.382 17.5517 122.576 17.5517C122.77 17.5517 124.111 13.8736 124.758 12.0345H127.182L129 1" stroke="#17B26A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.2">
            <rect x="76" y="5" width="20" height="20" rx="10" stroke="#17B26A" strokeWidth="2" />
        </g>
        <rect x="81" y="10" width="10" height="10" rx="5" fill="white" />
        <rect x="81" y="10" width="10" height="10" rx="5" stroke="#17B26A" strokeWidth="2" />
        <defs>
            <linearGradient id="paint0_linear_4992_376490" x1="64" y1="0" x2="64" y2="64" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="1" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg></>;
};
// @ts-nocheck
const Bottom = () => {
    return <><svg width="130" height="66" viewBox="0 0 130 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_17488_11472" maskUnits="userSpaceOnUse" x="1" y="1" width="128" height="64">
            <rect width="128" height="64" transform="matrix(-1 0 0 1 129 1)" fill="url(#paint0_linear_17488_11472)" />
        </mask>
        <g mask="url(#mask0_17488_11472)">
            <path opacity="0.2" d="M124.394 57.2759L129 65H1V1L2.81818 12.0345H5.24242C5.88889 13.8736 7.23031 17.5517 7.42425 17.5517C7.61819 17.5517 8.87878 19.7586 9.48484 20.8621L14.697 24.1724L17.7273 28.5862L22.5758 24.1724L25.1212 20.8621L28.7576 28.5862H32.5152L35.6667 20.8621C36.8788 19.7586 39.3515 17.5517 39.5455 17.5517C39.7394 17.5517 41.3232 15.3448 42.0909 14.2414H44.0303L48.6364 17.5517L52.6364 20.8621L54.4545 28.5862L59.9091 31.8966L62.697 35.2069L65.8485 40.7241L68.8788 47.9655H70.2121L73.8485 48.6552L77.3636 40.7241L80.3939 35.2069H82.2121L85.3636 31.8966L89.8485 35.2069H94.0909L98.3333 40.7241L102.576 47.3448L105.727 53.9655L109.121 57.2759L113.727 53.9655L117.364 57.2759L121 60.5862L124.394 57.2759Z" fill="#079455" />
        </g>
        <path d="M129 65L124.394 57.2759L121 60.5862L117.364 57.2759L113.727 53.9655L109.121 57.2759L105.727 53.9655L102.576 47.3448L98.3333 40.7241L94.0909 35.2069H89.8485L85.3636 31.8966L82.2121 35.2069H80.3939L77.3636 40.7241L73.8485 48.6552L70.2121 47.9655H68.8788L65.8485 40.7241L62.697 35.2069L59.9091 31.8966L54.4545 28.5862L52.6364 20.8621L48.6364 17.5517L44.0303 14.2414H42.0909C41.3232 15.3448 39.7394 17.5517 39.5455 17.5517C39.3515 17.5517 36.8788 19.7586 35.6667 20.8621L32.5152 28.5862H28.7576L25.1212 20.8621L22.5758 24.1724L17.7273 28.5862L14.697 24.1724L9.48484 20.8621C8.87878 19.7586 7.61819 17.5517 7.42425 17.5517C7.23031 17.5517 5.88889 13.8736 5.24242 12.0345H2.81818L1 1" stroke="#17B26A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.2">
            <rect x="-1" y="1" width="20" height="20" rx="10" transform="matrix(-1 0 0 1 53 4)" stroke="#17B26A" strokeWidth="2" />
        </g>
        <rect x="-1" y="1" width="10" height="10" rx="5" transform="matrix(-1 0 0 1 48 9)" fill="white" />
        <rect x="-1" y="1" width="10" height="10" rx="5" transform="matrix(-1 0 0 1 48 9)" stroke="#17B26A" strokeWidth="2" />
        <defs>
            <linearGradient id="paint0_linear_17488_11472" x1="64" y1="0" x2="64" y2="64" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="1" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
    </>;
};

Positive.Bottom = Bottom;