import { Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { ButtonPalette, ButtonSize } from 'components/Button/Button';
import { BodyPage } from 'elements/BodyPage';
import { HeaderPage } from 'elements/HeaderPage';
import { Table } from 'elements/Table';
import { User } from 'elements/User';
import { Icons } from 'icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Bounce, toast } from 'react-toastify';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { closeDrawer } from 'store/reducers/drawerSlice';
import { AddRegionPopup } from './AddRegionPopup';
import { CreateUserPopup } from './CreateUserPopup';
import styles from './styles.module.scss';
import { deletePostcode, deleteUprn } from 'store/reducers/leadSlice';

export const TeamPage = () => {
    const [showNewUserPopup, setNewUserPopupShow] = useState(false);
    const [showAddRegionPopup, setAddRegionPopupShow] = useState(false);
    const [editUserUuid, setEditUserUuid] = useState();
    const [deleteUserUuid, setDeleteUserUuid] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(deletePostcode());
        dispatch(deleteUprn());
    }, []);

    const { data: users = [], refetch } = usersAPI.useGetAllUsersQuery();
    const [deleteUser, { error: deleteUserError, isSuccess: isDeleteUserSuccess, isError: isDeleteUserError }] = usersAPI.useDeleteUserMutation();

    const data = users?.map((el) => ({
        ...el,
        key: el.uuid
    }));

    const config = [
        {
            label: 'Name',
            render: (data: { [key: string]: any; }) => <User
                name={data?.username} />,
            sortValue: (data: { [key: string]: any; }) => data.username,
        },
        {
            label: 'Email',
            render: (data: { [key: string]: any; }) => data.email,
            sortValue: (data: { [key: string]: any; }) => data.email,
        },
        {
            label: 'Level',
            render: (data: { [key: string]: any; }) => {
                if (data.is_admin) {
                    return <Tag color='green'>General Manager</Tag>;
                }

                if (data.is_user) {
                    return <Tag color='orange'>Local Manager</Tag>;
                }

                if (!data.is_user && !data.is_admin) {
                    return <Tag color='blue'>Worker</Tag>;
                }

                return '';
            },
            sortValue: (data: { [key: string]: any; }) => data.is_admin,
        },
        {
            label: 'Assign region',
            render: (data: { [key: string]: any; }) => {
                if (Array.isArray(data.regions) && data.regions.length) {
                    const arrOfRegions = data.regions.map((el) => {
                        return <Tooltip title={el.name} key={el.uuid}>
                            <Tag className={styles.tag}>{el.name}</Tag>
                        </Tooltip>;
                    });

                    return <div className={classNames(styles.tagsArea, {
                        [styles.tagsAreaOverflow]: arrOfRegions.length >= 3
                    })}>{arrOfRegions}
                        {arrOfRegions.length > 3 && <span className={styles.tagNumber}>+ {arrOfRegions.length - 3}</span>}
                    </div>;
                } else {
                    return 'No regions assigned';
                }
            },
            sortValue: (data: { [key: string]: any; }) => (Array.isArray(data.regions) && data.regions.length) ? data.regions.length : 0,
        },
        {
            label: '',
            render: (data: any) => {
                const handleDelete = async () => {
                    try {
                        await deleteUser(data.uuid);
                        setDeleteUserUuid(data.uuid);
                        refetch();
                    } catch (error) {
                        // @ts-ignore
                        console.error('Error deleting user:', error, deleteUserError);
                    }
                };

                return (
                    <div className={styles.actions}>
                        <button className={styles.actionBtn} onClick={handleDelete}>
                            <Icons.Delete />
                        </button>
                        <button className={styles.actionBtn}
                            onClick={() => {
                                setAddRegionPopupShow(true);
                                setEditUserUuid(data);
                            }}>
                            <Icons.Edit />
                        </button>
                    </div>
                );
            },
        }
    ];

    useEffect(() => {
        dispatch(closeDrawer());
    }, []);

    useEffect(() => {
        if (isDeleteUserSuccess) {

            toast.success('User has been successfully deleted!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDeleteUserSuccess]);

    useEffect(() => {
        if (isDeleteUserError) {

            toast.error('An error occurred while deleting user. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDeleteUserError]);

    return <>
        <HeaderPage
            title='Users'
            subtitle='View your management information across the organisation'>
            <div className={styles.headerGrid}>
                <Button size={ButtonSize.MD}
                    palette={ButtonPalette.SECONDARY_GRAY}
                >
                    <Icons.Download />
                    Generate invite token
                </Button>
                <Button size={ButtonSize.MD}
                    palette={ButtonPalette.PRIMARY}
                    onClick={() => setNewUserPopupShow(true)}
                >
                    <Icons.Plus />
                    Add member
                </Button>
            </div>

        </HeaderPage>
        <BodyPage>
            <Table
                config={config}
                data={data.filter((el) => el.uuid !== deleteUserUuid)}
            />
        </BodyPage>

        {showAddRegionPopup && <AddRegionPopup
            userData={editUserUuid}
            refetchUsers={refetch}
            onClose={() => setAddRegionPopupShow(false)}
        />}
        {showNewUserPopup && <CreateUserPopup
            onClose={() => setNewUserPopupShow(false)}
            refetchUsers={refetch}
        />}

    </>;
};
