import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { SeparatorIcon } from './icon/SeparatorIcon';
import style from './style.module.scss';

export interface IBreadcrumb {
    items: any;
    className?: string;
    separator?: any;
}

export const Breadcrumb: React.FC<IBreadcrumb> = ({ items, className, separator = <SeparatorIcon /> }) => {
    const itemsList = items.map((el: any, i: number, arr: any) => {
        if (i === arr.length - 1) {
            return <li key={nanoid()} className={style.breadcrumbItem}>
                {el.title}
            </li>;
        }

        return <li key={nanoid()} className={style.breadcrumbItem}>
            {el.title}
            <div className={style.separator}>
                {separator}
            </div>
        </li>;
    });

    return (<ul className={classNames(style.breadcrumbList, className)} data-testid="breadcrumb-list">
        {itemsList}
    </ul>);
};