import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'elements/Input';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { tokenAPI } from 'services/Token';
import { LoginInputs, defaultValues, validationSchema } from './FormValidation';
import styles from './style.module.scss';
import classNames from 'classnames';
import { StartFormLayout } from 'elements/StartFormLayout';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { useSSO } from 'hooks/useSSO';
import { SSOLink } from 'elements/SSOLink';
import { MicrosoftLogo } from 'icons/logo';

export const LoginPage: React.FC = () => {
    const { setToken } = useAuth();
    const [fetchLoginToken, { error: fetchLoginTokenError }] = tokenAPI.useFetchLoginTokenMutation();

    useSSO();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        // control
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: typeof defaultValues) => {
        const body = new URLSearchParams({
            username: data.email,
            password: data.password,
            scope: 'all'
        });

        const result = await fetchLoginToken(body);
        // @ts-ignore
        setToken(result?.data?.access_token);
    };

    useEffect(() => {
        // @ts-ignore
        if (fetchLoginTokenError && fetchLoginTokenError?.status) {
            // @ts-ignore
            toast.error(fetchLoginTokenError?.data?.detail, {
                position: 'top-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [fetchLoginTokenError]);

    watch(LoginInputs.EMAIL);
    watch(LoginInputs.PASSWORD);
    watch(LoginInputs.REMEMBER);
    console.log(`${process.env.REACT_APP_HERMES_API_URL}/oauth/sso/microsoft/login?return_url=${window.location.origin}`);
    return (<>
        <StartFormLayout>
            <div className={classNames(styles.header, 'text-center')}>
                <img className={classNames(styles.logo)} src='/assets/logo/logo-small.svg' alt='logo' />
                <h1 className={classNames(styles.title, 'display-sm', 'text-500', 'text-primary')}>Welcome back!</h1>
                <p className={classNames('text-md', 'text-400', 'text-tertiary')}>Welcome back! Please enter your details.</p>
            </div>
            {/* @ts-ignore */}
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <Input
                    type='text'
                    label='Username'
                    placeholder='Enter your username'
                    className={styles.input}
                    error={{
                        message: errors.email?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.EMAIL, { required: true }) }
                    }
                />
                <Input.Password
                    placeholder='Password'
                    label='Password'
                    className={styles.input}
                    error={{
                        message: errors.password?.message
                    }}
                    registerProps={
                        { ...register(LoginInputs.PASSWORD, { required: true }) } // trigger form submit 
                    }
                />
                <Button type="submit"
                    palette={ButtonPalette.PRIMARY}
                    size={ButtonSize.LG}
                    className={styles.button}
                >
                    Log in
                </Button>
            </form>

            <hr className={styles.separator} />

            <SSOLink
                url={`${process.env.REACT_APP_HERMES_API_URL}/oauth/sso/microsoft/login?return_url=${window.location.origin}`}
                text='Login with Microsoft'
                icon={<MicrosoftLogo />} />
        </StartFormLayout >
    </>);
};