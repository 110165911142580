import mapboxGl from 'mapbox-gl';
// import { Bounce, toast } from 'react-toastify';

export const fitBoundToSelectedPolygon = (map: mapboxGl.Map | null, features: any) => {
    if (!map) return;
    if (features.length === 0) return;
    if (features.length < 2) {
        return;
    }

    // @ts-ignore
    const coordinates = features.map((el) => el.geometry.coordinates);

    const bounds = new mapboxGl.LngLatBounds(
        [coordinates[0][1], coordinates[0][1], coordinates[1][0], coordinates[1][1]] as [number, number, number, number,]
    );

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of coordinates) {
        for (let i = 0; i < coord.length; i++) {
            console.log(coord);
            bounds.extend(coord as never as [number, number]);
        }
    }

    map.fitBounds(bounds, {
        padding: 140
    });
};