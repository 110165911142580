import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';
import { ListOfProperties, PropertyWithCensus } from 'models/Lead';

export const leadsAPI = createApi({
    reducerPath: 'leadsAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getAllAddressesByPostcode: build.query<ListOfProperties, { params: string; }>({
            query: ({ params }) => ({
                url: `/leads/addresses-by-postcode?${params}`,
                method: 'GET',
            }),
        }),
        getPropertyDetailsByUPRN: build.query<PropertyWithCensus, { uprn: number; }>({
            query: ({ uprn }) => ({
                url: `leads/property-details/${uprn}`,
                method: 'GET',
            }),
        }),

    })
});
