import { useTheme } from 'app/providers/ThemeProvider';
import classNames from 'classnames';
import { Navigation } from 'components/Navigation';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectDrawer } from 'store/reducers/drawerSlice';

export const GlobalLayout = () => {
    const { theme } = useTheme();
    const drawer = useSelector(selectDrawer);

    return (
        <div
            data-testid='App'
            className={classNames('app-layout', theme)}
        >
            <Navigation />
            <main className={classNames('pageContent', {
                'drawerOpen': drawer.isOpen
            })}>
                <Outlet />
            </main>
        </div>
    );
};