import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Input } from 'elements/Input';
import { Icons } from 'icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Bounce, toast } from 'react-toastify';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { CreateUserInputs, defaultValues, validationSchema } from './FormValidation';
import style from './styles.module.scss';
import { companiesAPI } from 'services/Companies/CompaniesService';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';

interface Props {
    onClose: (props?: any) => void;
    refetchUsers: any;
}

enum SuperUser {
    GENERAL = 'general',
    LOCAL = 'local',
    WORKER = 'worker'
}

export const CreateUserPopup: React.FC<Props> = ({ onClose, refetchUsers }) => {
    const { data: currentUser } = usersAPI.useGetCurrentUserQuery();

    const [companyUUID, setCompanyUUId] = useState<string>(currentUser?.is_admin ? currentUser.company_uuid : '');
    const { data: companies = [], isLoading } = companiesAPI.useGetAllCompaniesQuery(null, {
        skip: !currentUser?.is_user && !currentUser?.is_superuser
    });

    const [createUser, { error: createUserError, isSuccess, isError, error }] = usersAPI.useCreateUserMutation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const [isSuperUser, setSuperUser] = useState(SuperUser.WORKER);

    const onSubmit = async (data: typeof defaultValues) => {
        if ((!companyUUID || !currentUser?.company_uuid) && !currentUser?.is_admin) return;

        try {
            await createUser({
                company_uuid: companyUUID || currentUser?.company_uuid,
                username: data.name,
                email: data.email,
                password: data.password,
                is_admin: isSuperUser === SuperUser.GENERAL,
                is_user: isSuperUser === SuperUser.LOCAL,
            });
            onClose();
            refetchUsers();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createUserError);
        }
    };

    watch(CreateUserInputs.EMAIL);
    watch(CreateUserInputs.PASSWORD);
    watch(CreateUserInputs.NAME);

    useEffect(() => {
        // @ts-ignore
        if (isSuccess) {
            toast.success('User has been successfully created!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isError) {
            let message = '';
            // @ts-ignore
            if (error?.data && error?.data?.detail) {
                // @ts-ignore
                message = error?.data?.detail[0].toUpperCase() + error?.data?.detail.slice(1);
            } else {
                message = 'An error occurred while creating user. Please ensure all required parameters and configurations are set correctly. If the issue persists, contact support for assistance.';
            }
            toast.error(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isError]);

    return (<Modal onClose={onClose} className={style.modal}>
        <ModalHeader>
            <div className={style.decoration}>
                <Icons.AddUser />
            </div>
            <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Add new user</h2>
            <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Fill inputs and choose role</p>
        </ModalHeader>

        <ModalBody>
            {
                currentUser?.is_superuser && <div
                    className={style.input}>

                    {isLoading && <Skeleton height={60} />}
                    {!isLoading && <Select data={companies.map((el) => ({
                        value: el.uuid,
                        label: el.name
                    }))}
                        placeholder='Select company'
                        label='Company'
                        onChange={(value) => { setCompanyUUId(value); }}
                        value={companyUUID}
                        isBlock
                        width={{ width: '100%', maxWidth: '100%' }}
                        antdProps={
                            {
                                disabled: (currentUser?.is_admin && !currentUser?.is_superuser)
                            }
                        }
                    />}
                </div>
            }

            <Input
                className={style.input}
                label='Name'
                placeholder="Name"
                error={{
                    message: errors.name?.message
                }}
                registerProps={
                    { ...register(CreateUserInputs.NAME, { required: true }) }
                }
            />
            <Input
                className={style.input}
                label='Email'
                placeholder="Email"
                error={{
                    message: errors.email?.message
                }}
                registerProps={
                    { ...register(CreateUserInputs.EMAIL, { required: true }) }
                }
            />
            <Input.Password
                className={style.input}
                label='Password'
                placeholder="Password"
                error={{
                    message: errors.password?.message
                }}
                registerProps={
                    { ...register(CreateUserInputs.PASSWORD, { required: true }) }
                }
            />
            <div className={classNames(style.position)}>
                <p className={classNames('text-sm', 'text-500', 'text-secondary')}>Position</p>
                <Radio.Group onChange={(e) => setSuperUser(e.target.value)} value={isSuperUser}>
                    <Radio value={SuperUser.GENERAL}>General manager</Radio>
                    <Radio value={SuperUser.LOCAL}>Local manager</Radio>
                    <Radio value={SuperUser.WORKER}>Worker</Radio>
                </Radio.Group>
            </div>

        </ModalBody>
        <ModalFooter>
            <Button
                size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => {
                    onClose(false);
                    setSuperUser(SuperUser.WORKER);

                }}> Discard </Button>
            <Button
                type='submit'
                onClick={handleSubmit(onSubmit)}
                size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
            > Add user</Button>
        </ModalFooter>

    </Modal>);
};