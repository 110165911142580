export enum TimePeriod {
    y = 'y',
    m = 'm',
    w = 'w',
    q = 'q',
    custom = 'custom'
}

export const desiredDate = (timePeriod: TimePeriod) => {
    if (timePeriod === TimePeriod.y) {
        const lastYear = new Date().valueOf() - 365 * 24 * 60 * 60 * 1000; // 365 * 24 * 60 * 60 * 1000 
        const dateInPast = new Date(lastYear);
        return new Date(dateInPast);
    }

    if (timePeriod === TimePeriod.m) {
        const month = new Date().valueOf() - 30 * 24 * 60 * 60 * 1000; // 30 * 24 * 60 * 60 * 1000 
        const dateInPast = new Date(month);

        return new Date(dateInPast);
    }

    if (timePeriod === TimePeriod.q) {
        const week = new Date().valueOf() - (30 * 3) * 24 * 60 * 60 * 1000; // 7 * 24 * 60 * 60 * 1000 
        const dateInPast = new Date(week);

        return new Date(dateInPast);
    }

    if (timePeriod === TimePeriod.w) {
        const week = new Date().valueOf() - 7 * 24 * 60 * 60 * 1000; // 7 * 24 * 60 * 60 * 1000 
        const dateInPast = new Date(week);

        return new Date(dateInPast);
    }
    return 0;
};