import { BodyPage } from 'elements/BodyPage';
import { HeaderPage } from 'elements/HeaderPage';
import { Input } from 'elements/Input';
import styles from './styles.module.scss';
import { UPRNSMap } from 'components/UPRNSMap';
import { leadsAPI } from 'services/Leads/LeadsService';
import { useState } from 'react';
import { urlFormat } from 'utils/urlFormat';
import { Table } from 'elements/Table';
import { Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addPostcode, addUprn, selectLead } from 'store/reducers/leadSlice';
import { Button } from 'components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { EmptyCardNoData } from 'elements/EmptyState';
import { Skeleton } from 'elements/Skeleton';

export const TelemarketingPage = () => {
    const dispatch = useDispatch();
    const { postcode: postcodeFromStore = '' } = useSelector(selectLead);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [postcode, setPostcode] = useState<string>('' || postcodeFromStore);
    const { data: listOfProperties = [], isLoading } = leadsAPI.useGetAllAddressesByPostcodeQuery({ params: urlFormat({ postcode }) });

    const tableConfig = [
        {
            label: 'Street',
            render: (data: { [key: string]: any; }) => data.address,
            sortValue: (data: { [key: string]: any; }) => data.uprn
        },
        {
            label: 'Is residential',
            render: (data: { [key: string]: any; }) => data.is_residential ? <Tag color='green'>yes</Tag> : <Tag color='red'>No</Tag>,
            sortValue: (data: { [key: string]: any; }) => data.is_residential
        },
        {
            label: 'Actions',
            render: (data: { [key: string]: any; }) => {
                const { uprn } = data;

                return <Button onClick={() => {
                    dispatch(addUprn({
                        uprn
                    }));

                    navigate(`${pathname}/${uprn}`);
                }}>See census data</Button>;

            },
            sortValue: (data: { [key: string]: any; }) => data.is_residential
        },
    ];

    return <>
        <HeaderPage title='Demographics'
        > </HeaderPage>
        <BodyPage>
            {/* LS15 7Re */}
            <Input placeholder='Postcode' label='Postcode' value={postcode} onChange={(e: any) => {
                setPostcode(e.target.value);
                dispatch(
                    addPostcode({
                        postcode: e.target.value
                    })
                );
            }} />

            <div className={styles.body}>
                <UPRNSMap />
                {isLoading && <Skeleton />}
                {listOfProperties.length === 0 && <EmptyCardNoData />}
                {listOfProperties.length !== 0 && <Table data={listOfProperties} config={tableConfig} selectedCount={15} />}
            </div>
        </BodyPage>

    </>;
};