import { createApi } from '@reduxjs/toolkit/query/react';
import { RegionByGeometry, RegionByPostcodes, RegionByUPRNs, RegionExport, RegionsExport } from 'models/Regions';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const regionsAPI = createApi({
    reducerPath: 'regionsAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getAllRegions: build.query<RegionsExport, void>({
            query: () => ({
                url: '/regions?embed=campaigns',
                method: 'GET',
            }),
        }),
        getRegion: build.query<RegionExport, string>({
            query: (region_uuid) => ({
                url: `/regions/${region_uuid}?embed=campaigns`,
                method: 'GET',
            }),
        }),
        createRegionByGeometry: build.mutation<RegionsExport, RegionByGeometry>({
            query: (body) => ({
                url: '/regions/geometry',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        createRegionByPostcodes: build.mutation<RegionsExport, RegionByPostcodes>({
            query: (body) => ({
                url: '/regions/postcodes',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        createRegionByUPRN: build.mutation<RegionsExport, RegionByUPRNs>({
            query: (body) => ({
                url: '/regions/properties',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        deleteRegion: build.mutation<any, string>({
            query: (region_uuid) => ({
                url: `regions/${region_uuid}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Delete']
        }),

        getSalesStats: build.query<any, { region_uuid: string, params?: any; }>({
            query: ({ region_uuid, params }) => ({
                url: `/regions/${region_uuid}/stats/sales?${params}`,
                method: 'GET',
            }),
        }),

        getChurnStats: build.query<any, { region_uuid: string, params?: any; }>({
            query: ({ region_uuid, params }) => ({
                url: `/regions/${region_uuid}/stats/churn?${params}`,
                method: 'GET',
            }),
        }),
        getDoorKnockedStats: build.query<any, { region_uuid: string, params?: any; }>({
            query: ({ region_uuid, params }) => ({
                url: `/regions/${region_uuid}/stats/doors-knocked?${params}`,
                method: 'GET',
            }),
        }),
        getDemographics: build.query<any, { region_uuid: string; }>({
            query: ({ region_uuid }) => ({
                url: `/regions/${region_uuid}/demographics`,
                method: 'GET',
            }),
        }),
    })
});