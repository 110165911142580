import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ROUTES } from 'constants/routes';
import { Icons } from 'icons';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { regionsAPI } from 'services/Regions/RegionsService';
import styles from './styles.module.scss';

interface Props {
    onClose: () => void;
    region_uuid: string;
}
export const RemoveRegionModal: React.FC<Props> = ({ onClose, region_uuid }) => {
    // const { pathname } = useLocation();
    const navigate = useNavigate();
    const [deleteRegion, { error: deleteRegionError, isSuccess, isError }] = regionsAPI.useDeleteRegionMutation();

    const onSubmit = async () => {
        try {
            await deleteRegion(region_uuid);
            navigate(ROUTES.ROOT);
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error add region user:', error, deleteRegionError);
        }
    };
    useEffect(() => {
        // @ts-ignore
        if (isSuccess) {
            toast.success('Region remove!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isError) {
            toast.error('An error occurred while removing region. Please ensure all required parameters and configurations are set correctly. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isError]);

    return <Modal onClose={() => onClose()}>
        <ModalHeader className={styles.modalHeader}>
            <div className={styles.decoration}>
                <Icons.Attention />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Are you sure?</h2>
                <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Removed region cannot be restored!</p>
            </div>

        </ModalHeader>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => onClose()}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                type='submit'
                onClick={onSubmit}>
                Remove region
            </Button>
        </ModalFooter>
    </Modal>;
};