import classNames from 'classnames';
import styles from './style.module.scss';

export const Skeleton = ({ notFlickering = false, height = 50 }: any) => {
    return <div className={classNames(styles.skeleton, {
        [styles.notFlickering]: notFlickering
    })}
        style={{
            height
        }}></div>;
};

const TableEl = ({ size }: any) => {
    return <div className={classNames(styles.skeleton, styles.skeletonS, {
        [styles.l]: size === 'l'
    })}></div>;
};

Skeleton.TableCell = TableEl;

const ChartSkeleton = ({ notFlickering = false, className }: any) => {
    return (<div className={classNames(styles.chartSkeleton, className)}>
        <div className={classNames(styles.skeleton, styles.chart, {
            [styles.notFlickering]: notFlickering
        })}></div>
    </div>);
};

const ChartPlaceholder = () => {
    return <div className={classNames(styles.placeholder)}></div>;

};

Skeleton.ChartSkeleton = ChartSkeleton;
Skeleton.ChartPlaceholder = ChartPlaceholder;

export const Spinner = () => {
    return <div className={styles.parent}>
        <div className={styles.ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>;
};
Skeleton.Spinner = Spinner;