import { ProtectedRoute } from 'components/ProtectedRoute';
import { ROUTES } from 'constants/routes';
import { GlobalLayout } from 'elements/GlobalLayout';
import { AnalyticsPage } from 'pages/AnalyticsPage';
import { CampaignPage } from 'pages/CampaignPage';
import { CensusPage } from 'pages/CensusPage';
import { DashboardPage } from 'pages/DashboardPage';
import { LoginPage } from 'pages/LoginPage';
import { NotFound } from 'pages/NotFound';
import { RunPage } from 'pages/RunPage';
import { SettingsPage } from 'pages/Settings';
import { TeamPage } from 'pages/TeamPage';
import { TelemarketingPage } from 'pages/TelemarketingPage';
import { UI } from 'pages/UI';
import { Route, Routes } from 'react-router-dom';

export const RoutesComponent = () => {
    return (
        <>
            <Routes>
                <Route path={ROUTES.SIGN_IN} element={<LoginPage />} />
                {/* <Route path={`${ROUTES.SIGN_UP}/:token?`} element={<SignupPage />} /> */}

                <Route path={ROUTES.ROOT} element={<GlobalLayout />}>
                    <Route index element={<DashboardPage />} />

                    <Route
                        path={ROUTES.CREATE_USER}
                        element={
                            <ProtectedRoute>
                                <TeamPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={`${ROUTES.ROOT}/:param1`} element={<CampaignPage />} />
                    <Route path={`${ROUTES.ROOT}/:param1/:param2`} element={<RunPage />} />
                    <Route path={ROUTES.ANALYTICS} element={<AnalyticsPage />} />
                    <Route path={ROUTES.TELEMARKETING} element={<TelemarketingPage />} />

                    <Route path={`${ROUTES.TELEMARKETING}/:param1`} element={<CensusPage />} />

                    <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />
                    <Route path={`${ROUTES.UI}`} element={<UI />} />
                    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
};
