import React from 'react';
import Plot from 'react-plotly.js';
interface Props {
    plotData: any;
    title?: string;
    x?: string;
    y?: string;
    legend?: any;
    barmode?: 'group' | 'stack' | 'overlay' | 'relative' | undefined;
    shapes?: {
        type: 'path' | 'line' | 'rect' | 'circle' | undefined;
        x0?: number,
        y0?: number,
        x1?: number,
        y1?: number,
        xref?: any,
        yref?: any,
        line: {
            color: string,
            width?: number;
        };
    }[];
    layout?: any;
    style?: any;
}

const Chart: React.FC<Props> = ({ plotData, title, y, x, legend, barmode = 'group', shapes, layout, style }) => {
    const tickfont = {
        family: 'Roboto, sans-serif',
        size: 12,
        color: 'var(--text-primary)',
    };

    return <Plot
        data={plotData as any}

        data-testid='Chart'
        useResizeHandler={true}
        onError={(error: any) => {
            throw new Error(error.message);
        }}
        layout={{
            colorway: ['#0099FF', '#eeca86', '#e9ab0f', '#d47e30', '#9966FF', '#FF6699', '#FF9966', '#FF66CC', '#FF3399', '#FF3300', '#247bc7', '#daebfc', '#66CCFF', '#182844', '#042C5B',],
            title: {
                text: title,
                font: {
                    ...tickfont,
                    size: 20,
                },
            },
            responsive: true,
            barmode,
            showlegend: true,

            margin: {
                // autoexpand: false,
                l: 30,
                r: 10,
                t: 10
            },
            yaxis: {
                title: {
                    text: y,
                    standoff: 20
                },
            },
            xaxis: {
                title: {
                    text: x,
                    standoff: 10
                },
            },
            legend: {
                orientation: 'h',
                font: tickfont,
                ...legend
            },
            plot_bgcolor: 'var(--bg-primary)',
            paper_bgcolor: 'transparent',
            shapes,
            ...layout
        }
        }
        config={{ displayModeBar: false, responsive: true, showTips: false }}
        style={{
            width: '100%',
            minHeight: '550px',
            ...style
        }}
    />;

};

export default Chart;