import { Churn, StatisticTile } from 'elements/StatisticTile';
import { companiesAPI } from 'services/Companies/CompaniesService';
import { urlFormat } from 'utils/urlFormat';
import styles from './styles.module.scss';
// import { userAPI } from 'services/User';
import format from 'date-fns/format';
import { Skeleton } from 'elements/Skeleton';
import { usersAPI } from 'services/Hercules/HerculesUsersService';

const getDifference = (prev: number, current: number) => {
    return Math.floor((current - prev) / prev * 100);
};

export const OverviewTiles = () => {
    const { data: currentUser = [], isLoading }: any = usersAPI.useGetCurrentUserQuery();
    const currentMonth = (new Date().getMonth() - 2);
    const previousMonth = new Date().setMonth(currentMonth);

    const queries = {
        company_uuid: currentUser.company_uuid,

        params: {
            start: format(new Date(previousMonth), 'yyyy-MM-dd'),
            end: format(new Date(), 'yyyy-MM-dd'),
            granularity: 'm'
        }
    };

    const { data: companiesSalesData = [], isLoading: isSalesLoading } = companiesAPI.useGetSalesStatsQuery({
        company_uuid: queries.company_uuid,
        params: urlFormat(queries.params)
    }, {
        skip: isLoading
    });

    const { data: companiesChurnData = [], isLoading: isChurnLoading } = companiesAPI.useGetChurnStatsQuery({
        company_uuid: queries.company_uuid,
        params: urlFormat(queries.params)
    }, {
        skip: isLoading
    });

    const { data: companiesDoorKnockerData = [], isLoading: isDoorLoading } = companiesAPI.useGetDoorKnockedStatsQuery({
        company_uuid: queries.company_uuid,
        params: urlFormat(queries.params)
    }, {
        skip: isLoading
    });

    return <>

        <div className={styles.tilesGrid}>
            {(isSalesLoading || isLoading) ? <Skeleton />
                : <StatisticTile
                    title='Sales'
                    value={`${companiesSalesData[1]?.sales}k`}
                    growValue={
                        getDifference(companiesSalesData[0]?.sales, companiesSalesData[1]?.sales)
                    } />}

            {(isChurnLoading || isLoading) ? <Skeleton />
                : <Churn
                    title='PIC'
                    value={`${companiesChurnData[1]?.churn}k`}
                    growValue={
                        getDifference(companiesChurnData[0]?.churn, companiesChurnData[1]?.churn)
                    } />
            }
            {(isDoorLoading || isLoading) ? <Skeleton />
                : <StatisticTile
                    title='Doors Knocked'
                    value={companiesDoorKnockerData.length && `${companiesDoorKnockerData[1]['doors-knocked']}%`}
                    growValue={companiesDoorKnockerData.length &&
                        getDifference(companiesDoorKnockerData[0]['doors-knocked'],
                            companiesDoorKnockerData[1]['doors-knocked'])
                    } />
            }
        </div>
    </>;
};