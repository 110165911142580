import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { RegionsMap } from 'components/RegionsMap';
import { BodyPage } from 'elements/BodyPage';
import { ButtonGroup } from 'elements/ButtonGroup';
import { Drawer } from 'elements/Drawer';
import { EmptyState } from 'elements/EmptyState';
import { HeaderPage } from 'elements/HeaderPage';
import { Input } from 'elements/Input';
import { List } from 'elements/List';
import { RegionCard } from 'elements/RegionCard';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { regionsAPI } from 'services/Regions/RegionsService';
import { closeDrawer, openDrawer, selectDrawer } from 'store/reducers/drawerSlice';
import { addRegionData } from 'store/reducers/regionSlice';
import { fitBoundToSelectedPolygon } from 'utils/fitBoundToSelectedPolygon';
import { AddRegionModal } from './AddRegionModal';
import styles from './styles.module.scss';
import { deletePostcode, deleteUprn } from 'store/reducers/leadSlice';

enum ButtonGroupEnum {
    LIST = 'list',
    POINT = 'point'
}

export const DashboardPage = () => {
    const { data: currentUser = [], isLoading }: any = usersAPI.useGetCurrentUserQuery();

    const { map } = useMap();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(ButtonGroupEnum.POINT);
    const [isModalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { data: regions = [], refetch, } = regionsAPI.useGetAllRegionsQuery();
    const drawerState = useSelector(selectDrawer);
    useEffect(() => {
        dispatch(closeDrawer());
        dispatch(addRegionData({
            name: null,
            uuid: null
        }));
        refetch();
        dispatch(deletePostcode());
        dispatch(deleteUprn());
    }, []);

    const config = [
        {
            key: ButtonGroupEnum.LIST,
            children: <>
                <Icons.List />
            </>,
            onClick: () => {
                setActiveTab(ButtonGroupEnum.LIST);
            }
        },

        {
            key: ButtonGroupEnum.POINT,
            children: <>
                <Icons.Point />
            </>,
            onClick: () => {
                setActiveTab(ButtonGroupEnum.POINT);

                if (activeTab !== ButtonGroupEnum.POINT) {
                    return;
                }

                if (!drawerState.isOpen) {
                    dispatch(openDrawer());
                } else {
                    dispatch(closeDrawer());
                }
            }
        }
    ];

    const config2 = [
        {
            label: '',
            render: (data: { [key: string]: any; }) => <RegionCard
                title={data.name}
                address={data.address}
                horizontal
                onClickView={() => {
                    setActiveTab(ButtonGroupEnum.POINT);
                    dispatch(addRegionData({
                        name: data.name,
                        uuid: data.uuid
                    }));
                    fitBoundToSelectedPolygon(map, data.uuid);
                }}
                onClickCampaigns={() => {
                    navigate(`${pathname}${data.uuid}`);
                    dispatch(addRegionData({
                        name: data.name,
                        uuid: data.uuid
                    }));
                }}
            />,
        },
    ];

    const [inputValue, setInputValue] = useState('');
    const filteredData = regions.filter((el) => {
        return el.name.toLowerCase().includes(inputValue.toLowerCase());
    });

    if (isLoading) return <Skeleton />;

    return <>
        <HeaderPage
            title='Dashboard'
            subtitle='Please select a region'>
            <div className={styles.headerGrid}>
                {(currentUser.is_admin || currentUser.is_superuser) && <Button size={ButtonSize.MD}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => setModalOpen(true)}
                >
                    <Icons.Plus />
                    Add new region
                </Button>}
            </div>
        </HeaderPage>
        <BodyPage>
            <div className={styles.grid}>
                <Input
                    placeholder='Search region'
                    className={styles.inputSearch}
                    value={inputValue}
                    onChange={(e: any) => {
                        setInputValue(e.target.value);
                    }}
                />
                <div className={styles.actions}>

                    <ButtonGroup config={config} defaultValue='points' />
                </div>
            </div>
            {activeTab === ButtonGroupEnum.LIST && <>
                {filteredData.length === 0 && <EmptyState searchValue={inputValue} />}
                {!!filteredData.length && <List config={config2} data={filteredData} />}
            </>}
            {/* {activeTab === ButtonGroupEnum.POINT && <RegionsMap />} */}
            <div className={classNames({
                [styles.mapShow]: activeTab === ButtonGroupEnum.POINT,
                [styles.mapHide]: activeTab !== ButtonGroupEnum.POINT
            })}>
                <RegionsMap />
            </div>

        </BodyPage>

        <Drawer title='All Regions' subtitle='View all regions below and select an option'>
            {filteredData.map((el, i) => {
                return <RegionCard
                    key={i}
                    title={el.name}

                    onClickView={() => {
                        setActiveTab(ButtonGroupEnum.POINT);
                        fitBoundToSelectedPolygon(map, el.uuid);
                        dispatch(addRegionData({
                            name: el.name,
                            uuid: el.uuid
                        }));
                    }}
                    onClickCampaigns={() => {
                        navigate(`${pathname}${el.uuid}`);
                        dispatch(addRegionData({
                            name: el.name,
                            uuid: el.uuid
                        }));
                    }} />;
            })}
        </Drawer>

        {isModalOpen && <AddRegionModal onClose={() => setModalOpen(false)} refetch={refetch} />}
    </>;
};