import { Table, Tag } from 'antd';
import classNames from 'classnames';
import { Select } from 'elements/Select';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { regionsAPI } from 'services/Regions/RegionsService';
import { addCampaignData, selectCampaign } from 'store/reducers/campaignSlice';
import { closeDrawer } from 'store/reducers/drawerSlice';
import { addRegionData, selectRegion } from 'store/reducers/regionSlice';
import { RunStatus } from '..';
import { nameFormatter } from '../Marketing';

export const Campaign = () => {

    const { uuid: campaignUuid } = useSelector(selectCampaign);
    const { uuid: regionUuid } = useSelector(selectRegion);
    const [runsInCampaign, setRunsInCampaign] = useState([]);
    const dispatch = useDispatch();
    const { param1, param2 } = useParams();
    const { data: region = {}, isLoading: isRegionLoading, refetch: refetchRegionCampaigns }: any = regionsAPI.useGetRegionQuery(regionUuid || param1);

    const [run_uuid, setRunUuid] = useState('');

    const { data: runs = [], isLoading } = campaignRunAPI.useGetAllRunsQuery();
    useEffect(() => {
        dispatch(closeDrawer());
        refetchRegionCampaigns();
    }, []);

    useEffect(() => {
        if ('uuid' in region) {
            dispatch(addRegionData({
                uuid: region.uuid,
                name: region.name
            }));

            const currentCampaign = region.campaigns.find((el: any) => el.uuid === param2);
            dispatch(addCampaignData({

                uuid: currentCampaign.uuid,
                name: currentCampaign.name
            }));
        }

    }, [isRegionLoading]);

    useEffect(() => {
        if (runs.length) {
            const arrayOfRuns = runs.filter((el: any) => el.campaign_uuid === campaignUuid).map((el: any) => ({
                key: el.uuid,
                ...el
            }));
            setRunsInCampaign(arrayOfRuns);
        }

    }, [isLoading]);

    const { data: chunks = [], refetch } = campaignRunAPI.useGetChunksInRunQuery({ run_uuid }, {
        skip: !run_uuid
    });

    const { data: users = [] } = usersAPI.useGetAllUsersQuery();

    const selectOptions = users.filter((el) => {
        return el.is_admin === false && el.is_user === false;
    }).map((el) => {
        return {
            value: el.uuid,
            label: el.username
        };
    });
    const [assignRun,
        // { error: assignRunError, isSuccess, isError, isLoading: isAssignRunLoading }
    ] = campaignRunAPI.useAssignRunToWorkerMutation();

    const expandedRowRender = (rowData: any) => {
        const columns = [
            { title: 'Chunk №', dataIndex: 'chunk_id', },
            { title: 'Property per person', dataIndex: 'uprn_count', },
            { title: 'Assigned worker', dataIndex: 'worker_uuid', },
        ];

        const data = chunks.map((el, i) => {
            return {
                key: i,
                chunk_id: el.chunk_id,
                uprn_count: el.uprn_count,
                worker_uuid: <Select
                    value={el.worker_uuid}
                    data={selectOptions}
                    onChange={async (data) => {
                        try {
                            await assignRun({
                                run_uuid: rowData.uuid,
                                chunk_id: el.chunk_id,
                                worker_uuid: data
                            });
                            // onClose();
                            refetch();
                        } catch (error) {
                            // eslint-disable-next-line no-console
                            console.log('Updating creating user:', error);
                        }
                    }} />

            };
        });
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (name: string) => {
                return nameFormatter(name);
            }
        },
        { title: 'Chunk count', dataIndex: 'chunk_count', },
        {
            title: 'Active', dataIndex: 'is_active',
            render: (is_active: any) => is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,

        },
        {
            title: 'Status', dataIndex: 'status', render: (status: any) => {

                switch (status) {
                    case RunStatus.SUCCESS:
                        return <Tag color='green'>{RunStatus.SUCCESS}</Tag>;
                    case RunStatus.FAILED:
                        return <Tag color='red'>{RunStatus.FAILED}</Tag>;
                    case RunStatus.RUNNING:
                        return <Tag color='yellow'>{RunStatus.RUNNING}</Tag>;
                    case RunStatus.PENDING:
                        return <Tag color='blue'>{RunStatus.PENDING}</Tag>;
                }
            },
        },
    ];
    const [expandedRow, setExpandedRow] = useState<string[]>([]);
    console.log(expandedRow);
    return <>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Campaign runs</h2>
        <Table
            columns={columns}
            pagination={false}
            expandable={{
                expandedRowRender,
                onExpand: (isRowExpanded, record) => {
                    if (isRowExpanded === true) {
                        setExpandedRow([record.key]);
                        setRunUuid(record.uuid);
                    } else {
                        setExpandedRow([]);
                        setRunUuid('');
                    }
                },
                expandedRowKeys: expandedRow,
                rowExpandable: (val) => {
                    if (val.status === RunStatus.FAILED || val.status === RunStatus.PENDING || val.status === RunStatus.RUNNING) return false;
                    return true;
                }
            }}
            dataSource={runsInCampaign}
        /></>;
};