import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';
import { PenModelExport } from 'models/PenModel';

export const penModelAPI = createApi({
    reducerPath: 'penModelAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    endpoints: (build) => ({
        getAllPenModels: build.query<PenModelExport, void>({
            query: () => ({
                url: '/pen-models?embed=campaigns',
                method: 'GET',
            }),
        }),
    })
});
