import { Tag } from 'antd';
import { format } from 'date-fns';
import { NavItem } from 'elements/NavItem';
// import { RecentCard } from 'elements/RecentCard';
// import { SearchBox } from 'elements/SearchBox';
import { Skeleton } from 'elements/Skeleton';
import { Table } from 'elements/Table';
// import { Types } from 'elements/Tag/utils/TagTypes';
import { ThemeSwitcher } from 'elements/ThemeSwitcher';
import { Breadcrumbs } from './Breadcrumbs';
import { Buttons } from './Buttons';
import { CardsWithHeader } from './CardsWithHeader';
import { Checkboxes } from './Checkboxes';
import { Inputs } from './Inputs';
import { Selects } from './Selects';
import { Sliders } from './Sliders';
import styles from './styles.module.scss';
import { RegionCard } from 'elements/RegionCard';

export const UI = () => {
  const config = [
    {
      label: 'Name',
      render: (data: { [key: string]: any; }) => data.username,
      sortValue: (data: { [key: string]: any; }) => data.username,
    },
    {
      label: 'Email',
      render: (data: { [key: string]: any; }) => data.email,
      sortValue: (data: { [key: string]: any; }) => data.email,
    },
    {
      label: 'Active',
      render: (data: { [key: string]: any; }) => data.is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>In active</Tag>,
      sortValue: (data: { [key: string]: any; }) => data.is_active,
    },
    {
      label: 'Superuser',
      render: (data: { [key: string]: any; }) => data.is_superuser && <Tag color='green'>Superuser</Tag>,
      sortValue: (data: { [key: string]: any; }) => data.is_superuser,
    },
    {
      label: 'Created',
      render: (data: { [key: string]: any; }) => format(new Date(data.created_at), 'dd/MM/yy hh:mm'),
      sortValue: (data: { [key: string]: any; }) => data.created_at,
    },
    {
      label: 'Updated',
      render: (data: { [key: string]: any; }) => format(new Date(data.updated_at), 'dd/MM/yy hh:mm'),
      sortValue: (data: { [key: string]: any; }) => data.updated_at,
    },
    {
      label: 'Action',
      render: () => {
        return (
          <>
            <button onClick={() => { }}>
              <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M28 13C28.2652 13 28.5196 13.1054 28.7071 13.2929C28.8946 13.4804 29 13.7348 29 14C29 14.2652 28.8946 14.5196 28.7071 14.7071C28.5196 14.8946 28.2652 15 28 15H27L26.997 15.071L26.064 28.142C26.0281 28.6466 25.8023 29.1188 25.4321 29.4636C25.0619 29.8083 24.5749 30 24.069 30H15.93C15.4241 30 14.9371 29.8083 14.5669 29.4636C14.1967 29.1188 13.9709 28.6466 13.935 28.142L13.002 15.072C13.0005 15.048 12.9998 15.024 13 15H12C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14C11 13.7348 11.1054 13.4804 11.2929 13.2929C11.4804 13.1054 11.7348 13 12 13H28ZM24.997 15H15.003L15.931 28H24.069L24.997 15ZM22 10C22.2652 10 22.5196 10.1054 22.7071 10.2929C22.8946 10.4804 23 10.7348 23 11C23 11.2652 22.8946 11.5196 22.7071 11.7071C22.5196 11.8946 22.2652 12 22 12H18C17.7348 12 17.4804 11.8946 17.2929 11.7071C17.1054 11.5196 17 11.2652 17 11C17 10.7348 17.1054 10.4804 17.2929 10.2929C17.4804 10.1054 17.7348 10 18 10H22Z' fill='var(--table-icon-color)' />
              </svg>
            </button>
            <button>
              <svg width='42' height='40' viewBox='0 0 42 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M31 15.24C31.0008 15.1084 30.9756 14.9779 30.9258 14.8561C30.876 14.7343 30.8027 14.6235 30.71 14.53L26.47 10.29C26.3766 10.1973 26.2658 10.124 26.1439 10.0742C26.0221 10.0245 25.8916 9.99926 25.76 10C25.6284 9.99926 25.4979 10.0245 25.3761 10.0742C25.2543 10.124 25.1435 10.1973 25.05 10.29L22.22 13.12L11.29 24.05C11.1973 24.1435 11.124 24.2543 11.0742 24.3761C11.0245 24.4979 10.9993 24.6284 11 24.76V29C11 29.2652 11.1054 29.5196 11.2929 29.7071C11.4804 29.8947 11.7348 30 12 30H16.24C16.3799 30.0076 16.5199 29.9857 16.6508 29.9358C16.7818 29.8858 16.9007 29.8089 17 29.71L27.87 18.78L30.71 16C30.8013 15.9031 30.8757 15.7915 30.93 15.67C30.9397 15.5903 30.9397 15.5097 30.93 15.43C30.9347 15.3835 30.9347 15.3366 30.93 15.29L31 15.24ZM15.83 28H13V25.17L22.93 15.24L25.76 18.07L15.83 28ZM27.17 16.66L24.34 13.83L25.76 12.42L28.58 15.24L27.17 16.66Z' fill='var(--table-icon-color)' />
              </svg>

            </button>
          </>
        );
      },
    }
  ];
  const data = [
    {
      'extra_info': {},
      'created_at': '2019-08-24T14:15:22Z',
      'updated_at': '2019-08-24T14:15:22Z',
      'uuid': '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
      'company_uuid': 'aba69bec-94b3-48e8-9737-4f951465d0f8',
      'username': 'string',
      'email': 'string',
      'is_active': true,
      'is_superuser': true,
      'is_admin': false
    },
    {
      'extra_info': {},
      'created_at': '2019-08-24T14:15:22Z',
      'updated_at': '2019-08-24T14:15:22Z',
      'uuid': '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
      'company_uuid': 'aba69bec-94b3-48e8-9737-4f951465d0f8',
      'username': 'string',
      'email': 'string',
      'is_active': false,
      'is_superuser': false,
      'is_admin': false
    }
  ];

  return (<div className={styles.container}>

    <h2 className={styles.title}>
      Button
    </h2>
    <Buttons />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Selects
    </h2>
    <Selects />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Sliders
    </h2>
    <Sliders />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      ThemeSwitcher - new
    </h2>
    <ThemeSwitcher />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Card with header
    </h2>
    <CardsWithHeader />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Recent card
    </h2>

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Nav Item
    </h2>

    <NavItem
      link='/'
    >
      Home
    </NavItem>

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Checkboxes
    </h2>

    <Checkboxes />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Input
    </h2>

    <Inputs />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      SearchBox
    </h2>

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Breadcrumb
    </h2>

    <Breadcrumbs />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Skeleton
    </h2>

    <Skeleton />
    <Skeleton.ChartSkeleton />
    <Skeleton.TableCell />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      Table
    </h2>

    <Table config={config} data={data} />
    <Table config={config} data={[]} />

    <hr className={styles.separator} />

    <h2 className={styles.title}>
      RegionCard
    </h2>
    <RegionCard
      title='Office 1'
      address='1 Whitehall Road, London, Q1A 6XX' />
    <RegionCard
      title='Office 1'
      address='1 Whitehall Road, London, Q1A 6XX'
      horizontal />
  </div>);
};