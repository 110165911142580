import { createApi } from '@reduxjs/toolkit/query/react';
import { HermesBodySchema, HermesExport } from 'models/Hermes';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const hermesAPI = createApi({
    reducerPath: 'hermesAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_URL}`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getExport: build.mutation<HermesExport, HermesBodySchema>({
            query: (body) => ({
                url: '/export',
                method: 'POST',
                body,
            }),
        }),
    })
});