import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import React, { useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { regionsAPI } from 'services/Regions/RegionsService';
import style from './styles.module.scss';

interface Props {
    userData: any;
    refetchUsers: any;
    onClose: (props?: any) => void;
}

export const AddRegionPopup: React.FC<Props> = ({ userData, onClose, refetchUsers }) => {
    const { data: regions = [], isLoading: isRegionLoading } = regionsAPI.useGetAllRegionsQuery();
    const [selectedRegions, setSelectedRegions] = useState<string[]>(userData.regions.map((el: any) => el.uuid));

    const [addRegion, { error: addRegionError, isLoading: isAddRegionLoading, isSuccess: isAddRegionSuccess, isError: isAddRegionError }] = usersAPI.useAddRegionToUserMutation();
    const [deleteRegion, { error: deleteRegionError, isSuccess: isDeleteRegionSuccess, isError: isDeleteRegionError }] = usersAPI.useDeleteRegionFromUserMutation();

    const onSubmit = async () => {
        if (selectedRegions.length === 0) {
            refetchUsers();
            onClose();
            return;
        }
        try {
            await addRegion({
                user_uuid: userData.uuid,
                body: selectedRegions
            });
            refetchUsers();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error add region user:', error, addRegionError);
        }
    };

    const onClear = async (region_uuid: string) => {
        try {
            await deleteRegion({
                user_uuid: userData.uuid,
                body: [region_uuid]
            });
            refetchUsers();

        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error delete region user:', error, deleteRegionError);
        }
    };

    const regionsOptions = regions.map((el) => {
        return {
            value: el.uuid,
            label: el.name
        };
    });

    useEffect(() => {
        // @ts-ignore
        if (isAddRegionSuccess) {
            toast.success('Regions were successfully added to the user!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isAddRegionSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isAddRegionError) {
            toast.error('An error occurred while adding regions. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isAddRegionError]);

    // 

    useEffect(() => {
        // @ts-ignore
        if (isDeleteRegionSuccess) {
            toast.success('Region was successfully removed from the user!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDeleteRegionSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isDeleteRegionError) {
            toast.error('An error occurred while deleting region. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDeleteRegionError]);

    return (<Modal onClose={onClose} className={style.modal}>
        <ModalHeader>
            <div className={style.decoration}>
                <Icons.Point />
            </div>
            <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Edit Assigned Region</h2>
            <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Add a region for a local manager</p>
        </ModalHeader>

        <ModalBody>
            <div className={style.input}>
                {isRegionLoading && <Skeleton />}
                {!isRegionLoading && <Select
                    isTags
                    width={{ width: '100%', maxWidth: '500px' }}
                    value={selectedRegions}
                    data={regionsOptions}
                    onChange={setSelectedRegions}
                    antdProps={{
                        onDeselect: (val: any) => {
                            onClear(val);
                        }
                    }}
                />}

            </div>
            {/* {selectedRegions?.join('')} */}
        </ModalBody>
        <ModalFooter>
            <Button
                size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => {
                    onClose(false);
                    setSelectedRegions([]);
                }}> Discard </Button>
            <Button
                type='submit'
                onClick={onSubmit}
                size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                isLoading={isAddRegionLoading}
            > Save</Button>
        </ModalFooter>

    </Modal>);
};