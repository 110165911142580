import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ButtonGroup } from 'elements/ButtonGroup';
import { CSVReader } from 'elements/CSVReader';
import { Input } from 'elements/Input';
import { Icons } from 'icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { regionsAPI } from 'services/Regions/RegionsService';
import { DrawRegionModal } from './DrawRegionModal';
import { CreateRegionInputs, defaultValues, validationSchema } from './FormValidation';
import styles from './styles.module.scss';
import { Skeleton } from 'elements/Skeleton';
import { Bounce, toast } from 'react-toastify';

interface Props {
    onClose: () => void;
    refetch?: any;
}

enum TypeOfData {
    UPRN = 'uprn',
    POSTCODES = 'postcodes',
    DRAW = 'draw'
}
export const AddRegionModal: React.FC<Props> = ({ onClose, refetch = () => { } }) => {
    const [isDrawModalShown, setDrawModalShown] = useState(false);
    const { data: currentUser, isLoading: getCurrentUserIsLoading } = usersAPI.useGetCurrentUserQuery();

    const [polygon, setPolygon] = useState<any | null>(null);

    const [usedTypeOfData, setTypeOfData] = useState(TypeOfData.POSTCODES);

    // createRegion
    const [createRegionByPostcodes, { error: createRegionByPostcodesError, isLoading: isCreateRegionByPostcodesLoading, isSuccess: isByPostcodesSuccess, isError: isByPostcodesError }] = regionsAPI.useCreateRegionByPostcodesMutation();
    const [createRegionByGeometry, { error: createRegionByGeometryError, isLoading: isCreateRegionByGeometryLoading, isSuccess: isByGeometrySuccess, isError: isByGeometryError }] = regionsAPI.useCreateRegionByGeometryMutation();
    const [createRegionByUPRNs, { error: createRegionByUPRNsError, isLoading: isCreateRegionByUPRNsLoading, isSuccess: isByUPRNsSuccess, isError: isByUPRNsError }] = regionsAPI.useCreateRegionByUPRNMutation();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const handleCreateByPostcodes = async (data: typeof defaultValues) => {
        if (!currentUser?.company_uuid || !data.postcodes) return;

        try {
            await createRegionByPostcodes({
                company_uuid: currentUser.company_uuid,
                name: data.name,
                postcodes: data.postcodes,
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createRegionByPostcodesError);
        }
    };

    const handleCreateByUprns = async (data: typeof defaultValues) => {
        if (!currentUser?.company_uuid || !data.uprns) return;

        try {
            await createRegionByUPRNs({
                company_uuid: currentUser.company_uuid,
                name: data.name,
                uprns: data.uprns,
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createRegionByUPRNsError);
        }
    };

    const handleCreateByDraw = async (data: typeof defaultValues) => {
        if (!currentUser?.company_uuid || !polygon) return;
        try {
            await createRegionByGeometry({
                company_uuid: currentUser.company_uuid,
                name: data.name,
                geometry: polygon,
                geometry_native_srid: 4326
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createRegionByGeometryError);
        }
    };

    const onSubmit = async (data: typeof defaultValues) => {
        if (usedTypeOfData === TypeOfData.POSTCODES) {
            await handleCreateByPostcodes(data);
        }

        if (usedTypeOfData === TypeOfData.UPRN) {
            await handleCreateByUprns(data);
        }

        if (usedTypeOfData === TypeOfData.DRAW) {
            await handleCreateByDraw(data);
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (isByPostcodesSuccess || isByGeometrySuccess || isByUPRNsSuccess) {
            toast.success('New region has been successfully created!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isByPostcodesSuccess, isByGeometrySuccess, isByUPRNsSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isByGeometryError || isByPostcodesError || isByUPRNsError) {
            toast.error('An error occurred while creating region. Please ensure all required parameters and configurations are set correctly. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isByGeometryError, isByPostcodesError, isByUPRNsError]);

    watch(CreateRegionInputs.NAME);
    watch(CreateRegionInputs.POSTCODES);
    watch(CreateRegionInputs.UPRNs);
    const config = [
        {
            key: TypeOfData.UPRN,
            children: 'By UPRN',
            onClick: () => setTypeOfData(TypeOfData.UPRN)
        },
        {
            key: TypeOfData.POSTCODES,
            children: 'By postcodes',
            onClick: () => setTypeOfData(TypeOfData.POSTCODES)
        },
        {
            key: TypeOfData.DRAW,
            children: 'Draw',
            onClick: () => setTypeOfData(TypeOfData.DRAW)
        }
    ];
    return (<>
        <Modal onClose={() => onClose()}>
            <ModalHeader>
                <div className={styles.decoration}>
                    <Icons.Point />
                </div>
                <div className={classNames(styles.textBlock)}>
                    <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Add Region</h2>
                    <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Set configurations for a new region</p>

                    {/* <div className={classNames('text-tertiary', 'text-sm', 'text-400', styles.hint)}>
                        <Tooltip placement="right" title='Some help text'>
                            <div className={styles.hintText}>
                                <Icons.Help width={15} height={15} />Help
                            </div>
                        </Tooltip>
                    </div> */}
                </div>

            </ModalHeader>
            <ModalBody className={styles.modalGridWrap}>
                {getCurrentUserIsLoading && <Skeleton height={400} />}
                {!getCurrentUserIsLoading && <>
                    <div className={styles.modalGrid}>
                        <Input
                            className={styles.name}
                            type='text'
                            label='Name*'
                            placeholder='Add your region name'
                            error={{
                                message: errors.name?.message
                            }}
                            registerProps={
                                { ...register(CreateRegionInputs.NAME, { required: true }) }
                            }
                        />
                        {/* <Input
                        className={styles.office}

                        type='text'
                        label='Office Address*'
                        placeholder='Street Address' // ! todo
                    />
                    <Input
                        className={styles.city}
                        type='text'
                        label='City*'
                        placeholder='City' // ! todo
                    /> */}

                    </div>

                    <div className={classNames(styles.formField)}>
                        <p className='text-sm text-500 text-secondary'>Type of used data</p>
                        <ButtonGroup config={config} defaultValue={TypeOfData.POSTCODES} />
                    </div>
                    {
                        usedTypeOfData === TypeOfData.UPRN && <div className={classNames(styles.formField, styles.addresses)}>
                            <label className='text-sm text-500 text-secondary'>Addresses*</label>
                            <CSVReader className={styles.csvBtn}
                                onChange={(data) => {
                                    const preparedData = data.flat(5).filter((el: string) => el[0]).map((el) => +el);
                                    console.log(preparedData);
                                    setValue(CreateRegionInputs.UPRNs, preparedData);
                                }} />
                            {errors.uprns && errors.uprns.message && <p>{errors.uprns.message}</p>}
                        </div>
                    }
                    {
                        usedTypeOfData === TypeOfData.POSTCODES && <div className={classNames(styles.formField, styles.postcodes)}>
                            <label className='text-sm text-500 text-secondary'>Postcodes*</label>
                            <CSVReader className={styles.csvBtn}
                                onChange={(data: any) => {
                                    const preparedData = data.flat(5).filter((el: string) => el[0]);
                                    setValue(CreateRegionInputs.POSTCODES, preparedData);
                                }} />
                            {errors.postcodes && errors.postcodes.message && <p>{errors.postcodes.message}</p>}
                        </div>
                    }
                    {
                        usedTypeOfData === TypeOfData.DRAW && <div className={classNames(styles.formField, styles.btnDrawRegion)}>
                            <label className='text-sm text-500 text-secondary'>Draw region*</label>

                            <Button size={ButtonSize.SM} palette={ButtonPalette.SECONDARY_GRAY} onClick={() => setDrawModalShown(true)}>
                                <Icons.Draw />
                                Draw region</Button>
                        </div>
                    }</>
                }
            </ModalBody>
            <ModalFooter>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => onClose()}
                >
                    Discard
                </Button>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.PRIMARY}
                    type='submit'
                    isLoading={isCreateRegionByGeometryLoading || isCreateRegionByPostcodesLoading || isCreateRegionByUPRNsLoading}
                    // @ts-ignore
                    onClick={handleSubmit(onSubmit)}
                >
                    Add region
                </Button>
            </ModalFooter>
        </Modal>
        {isDrawModalShown && <DrawRegionModal
            onClose={() => setDrawModalShown(false)}
            setPolygon={(data) => setPolygon(data)} />}
    </>);
};