import { createApi } from '@reduxjs/toolkit/query/react';
import { CampaignsExport } from 'models/Campaigns';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const campaignMarketingRunAPI = createApi({
    reducerPath: 'campaignMarketingRun',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getAllMarketingRuns: build.query<any, void>({
            query: () => ({
                url: '/campaign-marketing-runs',
                method: 'GET',
            }),
        }),
        createCampaignMarketingRunExport: build.mutation<CampaignsExport, {
            body: {
                campaign_uuid: string;
                max_property_count: number;
                name: string;
            };
        }>({
            query: ({ body }) => ({
                url: '/campaign-marketing-runs',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),

    })
});