import { createSlice } from '@reduxjs/toolkit';

const regionSlice = createSlice({
    name: 'region',
    initialState: {
        uuid: null,
        name: null
    },
    reducers: {
        addRegionData: (state, action) => {
            state.uuid = action.payload.uuid;
            state.name = action.payload.name;
        },
        removeRegionUuid: (state) => {
            state.uuid = null;
        },
    }
});

export const selectRegion = (state: any) => state.region;

export const {
    addRegionData,
} = regionSlice.actions;

export default regionSlice.reducer;
