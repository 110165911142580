import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { useAuth } from 'hooks/useAuth';
import mapboxGl from 'mapbox-gl';
import { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styles from './Map.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { leadsAPI } from 'services/Leads/LeadsService';
import { useSelector } from 'react-redux';
import { selectLead } from 'store/reducers/leadSlice';
import { urlFormat } from 'utils/urlFormat';
import { fitBoundToSelectedPolygon } from './fitBounds/index';

(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;

const host = process.env.REACT_APP_HERMES_API_URL;

export const UPRNSMap = () => {
    const { postcode } = useSelector(selectLead);
    const { setDraw, setMap, map } = useMap();
    const { token } = useAuth();

    const mapRef = useRef<HTMLDivElement>(null);

    const { data: listOfProperties = [] } = leadsAPI.useGetAllAddressesByPostcodeQuery({ params: urlFormat({ postcode }) });

    useEffect(() => {
        if (!mapRef.current) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            center: [-1.748506, 52.380998],
            transformRequest: (url: any): any => {
                if (url.startsWith(host)) {
                    return {
                        url,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };
                }
            },
            style: {
                version: 8,
                sources: {
                    base: {
                        type: 'raster',
                        tiles: ['https://os.fibreplanner.io/z-{z}_x-{x}_y-{y}'],
                        maxzoom: 20,
                        tileSize: 256,
                    },
                },
                layers: [
                    {
                        id: 'base-layer',
                        type: 'raster',
                        source: 'base',
                        minzoom: 0,
                        maxzoom: 24,
                    },
                ],
            },
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            maxBounds: [-10.731977871337193, 49.59411301175666, 1.9010451201391163, 61.32814923895637],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });

        map.on('load', async () => {
            console.log(map);
            setMap(map);
        });

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, []);

    useEffect(() => {
        console.log(map, listOfProperties);

        if (!map) return;
        if (listOfProperties.length === 0) return;

        if (map.getSource('properties-source')) {
            map.removeLayer('properties-layer');
            map.removeSource('properties-source');
        }

        if (listOfProperties.length) {

            const arrOfPoints = listOfProperties.map((el) => {
                return {
                    'type': 'Feature',
                    'geometry': el.geometry,
                    properties: {
                        name: el.address,
                        is_residential: Number(el.is_residential)
                    }
                };
            });

            map.addSource('properties-source', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': arrOfPoints as any
                }
            });

            map.addLayer({
                'id': 'properties-layer',
                'type': 'circle',
                'source': 'properties-source',
                'paint': {
                    'circle-radius': {
                        'base': 1.75,
                        'stops': [
                            [12, 2],
                            [22, 90]
                        ]
                    },
                    'circle-color': [
                        'match',
                        ['get', 'is_residential'],
                        1, 'green',
                        0, 'red',
                        'gray'
                    ],
                    'circle-stroke-width': 1,
                    'circle-stroke-color': 'black'
                },
            });

            fitBoundToSelectedPolygon(map, arrOfPoints);

            const popup = new mapboxGl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('mouseenter', 'properties-layer', (e) => {
                map.getCanvas().style.cursor = 'pointer';

                // @ts-ignore
                const coordinates = e.features[0].geometry.coordinates.slice();
                // @ts-ignore
                const description = e.features[0].properties.name;

                popup.setLngLat(coordinates).setHTML(description).addTo(map);
            });

            map.on('mouseleave', 'properties-layer', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
        }
    }, [map, listOfProperties]);

    return (
        <>
            <div className={classNames(styles.mapContainer)} data-testid='Map'>
                <div className={styles.map} id='map' ref={mapRef} />
            </div>
        </>
    );
};