import React, { Fragment } from 'react';
import styles from './style.module.scss';

interface Props {
    data: any;
    config: any;
}
export const ListBody: React.FC<Props> = ({ data, config }) => {

    const rowsJSX = data.map((dataItem: any, i: any) => {
        const configRenderResult = config.map((configItem: any, i: number) => {
            return <Fragment key={i}>{configItem.render(dataItem)}</Fragment>;
        });

        return <Fragment key={i + 10}>
            {configRenderResult}
        </Fragment>;
    });
    return <div className={styles.body}>
        {rowsJSX}
    </div>;
};