import classNames from 'classnames';
import { BodyPage } from 'elements/BodyPage';
import { HeaderPage } from 'elements/HeaderPage';
import { Skeleton } from 'elements/Skeleton';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { closeDrawer } from 'store/reducers/drawerSlice';
import { ChurnAnalisys } from './ChurnAnalisys';
import { DoorKnocked } from './DoorKnocked';
import { OverviewTiles } from './OverviewTiles';
import { SalesAnalys } from './SalesAnalys';
import { SalesOverview } from './SalesOverview';
import styles from './styles.module.scss';
import { deletePostcode, deleteUprn } from 'store/reducers/leadSlice';

export const AnalyticsPage = () => {
    const { data: currentUser = [], isLoading }: any = usersAPI.useGetCurrentUserQuery();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(closeDrawer());

        dispatch(deletePostcode());
        dispatch(deleteUprn());
    }, []);

    if (isLoading) return <Skeleton />;

    return <>
        <HeaderPage
            title='Analytics'
            subtitle={currentUser.is_admin ? 'View your analytics across the organisation' :
                'View your analytics across your regions and campaigns'}>
            {/* <div className={styles.headerGrid}>
                <Button size={ButtonSize.MD}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => setModalOpen(true)}
                >
                    <Icons.Download />
                    Export report
                </Button>
            </div> */}
        </HeaderPage>
        <BodyPage>
            {currentUser.is_admin && <div className={classNames(styles.overview)}>
                <div className={classNames(styles.sectionHeader)}>
                    <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Overview</h2>
                    {/* 
                    <Select
                        data={companiesOptions}
                        placeholder='Select Company'
                        // onChange={(value) => {
                        //     setCompanyUuid(value);
                        // }}
                    /> */}
                </div>
                <OverviewTiles />
                <SalesOverview />
            </div>
            }
            <div className={styles.analyticsGrid}>
                <SalesAnalys />
                <DoorKnocked />
            </div>
            <div className={styles.block}>
                <ChurnAnalisys />
            </div>
        </BodyPage>
    </>;
};