import { Radio, Table, Tag } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Error } from 'components/Error';
import { RegionMap } from 'components/RegionMap';
import { ROUTES } from 'constants/routes';
import { format } from 'date-fns';
import { BodyPage } from 'elements/BodyPage';
import { Breadcrumb } from 'elements/Breadcrumb';
import { Drawer } from 'elements/Drawer';
import { Dropdown } from 'elements/Dropdown';
import { EmptyCardNoData } from 'elements/EmptyState';
import { HeaderPage } from 'elements/HeaderPage';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { penModelAPI } from 'services/PenModel';
import { regionsAPI } from 'services/Regions/RegionsService';
import { addCampaignData, addMapCampaignUuid, removeCampaignData, selectCampaign } from 'store/reducers/campaignSlice';
import { closeDrawer, openDrawer, selectDrawer } from 'store/reducers/drawerSlice';
import { selectRegion } from 'store/reducers/regionSlice';
import { AddCampaignModal } from './AddCampaignModal';
import { EditCampaignModal } from './EditCampaignModal';
import { KnockListForm } from './KnockListForm';
import { MarketingForm } from './MarketingForm';
import { ModalAnalytic } from './ModalAnalytic';
import { RemoveRegionModal } from './RemoveRegionModal';
import styles from './styles.module.scss';

enum TypeOfExport {
    MARKETING = 'marketing',
    DIGITAL_MARKETING = 'digital_marketing',
    DOOR_TO_DOOR = 'door_to_door'
}

export const CampaignPage = () => {
    const { param1 } = useParams();

    const { data: currentUser = [] }: any = usersAPI.useGetCurrentUserQuery();
    const regionUuid = useSelector(selectRegion);
    const [firstCampaign, setFirstCampaign] = useState('');
    const [showModalAnalytic, setModalAnalytic] = useState(false);
    const [showModal, setModalOpen] = useState(false);
    const [showModalDeleteRegion, setModalDeleteRegionOpen] = useState(false);
    const { data: region = {}, isLoading, error, refetch }: any = regionsAPI.useGetRegionQuery(regionUuid.uuid || param1);
    const [selectedCampaign, setSelectedCampaign] = useState<{ name: string; uuid: string; }>();
    const drawerState = useSelector(selectDrawer);
    const [loading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { uuidForMap } = useSelector(selectCampaign);
    const { data: penModels = [],
    } = penModelAPI.useGetAllPenModelsQuery();

    const [deleteCampaign, { error: deleteCampaignError, isSuccess: isDeleteCampaignSuccess }] = campaignAPI.useDeleteCampaignMutation();
    const [expandedRow, setExpandedRow] = useState<string[]>([]);

    const [value, setValue] = useState(TypeOfExport.DOOR_TO_DOOR);

    const [isEditModalOpen, setEditModalOpen] = useState<{
        isOpen: boolean,
        campaign_uuid: string;
    }>({
        isOpen: false,
        campaign_uuid: ''
    });
    useEffect(() => {
        dispatch(closeDrawer());
    }, []);

    useEffect(() => {
        if (!drawerState.isOpen) {
            setSelectedCampaign({
                name: '',
                uuid: ''
            });
        }
    }, [drawerState.isOpen]);

    useEffect(() => {
        if (Array.isArray(region.campaigns) && region.campaigns.length) {
            setFirstCampaign(region.campaigns[0].uuid);
        }
    }, [region]);

    useEffect(() => {
        // @ts-ignore
        if (isDeleteCampaignSuccess) {
            toast.success('Campaign has been successfully removed!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDeleteCampaignSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (deleteCampaignError) {
            toast.error('An error occurred while removing campaign. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [deleteCampaignError]);

    if (error) {
        return <Error />;
    }

    if (isLoading) {
        return <Skeleton />;
    }

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    const handleDelete = async (campaign_uuid: string) => {
        try {
            await deleteCampaign({ campaign_uuid });
            refetch();
        } catch (err) {
            console.error(err);
        }
    };

    const columns: any = [
        Table.EXPAND_COLUMN,
        {
            key: 'campaign_name',
            title: 'Campaign Name',
            dataIndex: 'name',
        },
        {
            key: 'updated',
            title: 'Updated',
            dataIndex: 'updated_at',
            render: (updated_at: string) => format(new Date(updated_at), 'dd.MM.yyyy'),

        },
        {
            key: 'actions',
            title: '',
            dataIndex: 'uuid',
            render: (uuid: any, data: any) => {
                console.log('uuid', uuid, data);
                // const data = { name: '' };
                const style = selectedCampaign?.uuid === uuid ? ButtonPalette.PRIMARY : ButtonPalette.SECONDARY_GRAY;
                const text = selectedCampaign?.uuid === uuid ? 'Selected' : 'Select';
                const condition = (uuidForMap === uuid || firstCampaign === uuid);

                return <div className={styles.btnGrid}>

                    <Button
                        size={ButtonSize.MD}
                        palette={condition ? ButtonPalette.TERTIARY_GRAY : ButtonPalette.SECONDARY_GRAY}
                        isDisabled={loading}
                        onClick={() => {
                            dispatch(addMapCampaignUuid({
                                uuid,
                            }));
                            setFirstCampaign(uuid);
                        }}>
                        {condition ? 'On map' : 'View on map'}
                    </Button>
                    <Button
                        size={ButtonSize.MD}
                        palette={style}
                        onClick={() => {
                            setSelectedCampaign({
                                uuid,
                                name: data.name
                            });
                            dispatch(addCampaignData({
                                uuid,
                                name: data.name,
                            }));

                            if (drawerState.isOpen === true && selectedCampaign?.uuid !== uuid) {
                                dispatch(openDrawer());
                            } else if (drawerState.isOpen === true && selectedCampaign?.uuid === uuid) {
                                dispatch(closeDrawer());
                                dispatch(removeCampaignData());
                                setSelectedCampaign({
                                    uuid: '',
                                    name: ''
                                });
                            } else {
                                dispatch(openDrawer());
                            }
                        }}>
                        {text}
                    </Button>
                    <Dropdown data={[
                        {
                            content: <><Icons.Edit /> Edit</>,
                            onChange: () => {
                                setEditModalOpen({
                                    isOpen: true,
                                    campaign_uuid: uuid || ''
                                });
                            }
                        },
                        {
                            content: <><Icons.Delete /> Delete</>,
                            onChange: () => handleDelete(uuid)
                        },
                    ]} />
                </div>;
            },

        },
    ];

    return <>
        <HeaderPage
            title={region.name}
            subtitle='Please select a campaign'
            className={styles.headerGrid}
            withBreadcrumb
            breadcrumb={
                <Breadcrumb className={styles.breadcrumb} items={[
                    {
                        title: <Link to={ROUTES.ROOT}><Icons.Home /></Link>,
                    },
                    {
                        title: <Link to={ROUTES.ROOT}>Region</Link>,
                    },
                    {
                        title: region.name,
                    },
                ]}
                />
            }>

            <div className={classNames(styles.controls, styles.headerBtn)}>

                <Button size={ButtonSize.MD}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => setModalOpen(true)}
                >
                    <Icons.Plus />
                    Add new campaign
                </Button>
                {
                    (currentUser.is_admin || currentUser.is_superuser) && <Button size={ButtonSize.MD}
                        palette={ButtonPalette.SECONDARY_GRAY}
                        onClick={() => setModalDeleteRegionOpen(true)}
                        className={styles.headerBtn}
                    >
                        Remove region
                    </Button>
                }
            </div>
        </HeaderPage>
        <BodyPage className={classNames(styles.bodyGrid, {
            [styles.drawerOpen]: drawerState.isOpen
        })}>

            {!isLoading && <RegionMap region={region} onloading={setIsLoading} firstCampaign={firstCampaign} />}

            {!isLoading && region.campaigns?.length !== 0 && <div className={styles.pageScroll}>
                <h2 className={classNames('text-primary', 'text-lg', 'text-500', styles.subtitle)}>Analytics</h2>
                <Button size={ButtonSize.MD} palette={ButtonPalette.SECONDARY_COLOR}
                    onClick={() => setModalAnalytic(true)}
                >Demographic breakdown</Button>
                <hr className={styles.separator} />
                <h2 className={classNames('text-primary', 'text-lg', 'text-500', styles.subtitle)}>Campaigns</h2>

                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record: any) => {
                            const { created_at, pen_model_uuid, updated_at, uuid } = record;
                            const penModel = penModels.find((el) => el.uuid === pen_model_uuid);

                            const htmlStructure = <div className={styles.expandedTable}>
                                <div>
                                    <div><strong>Created:</strong> {format(new Date(created_at), 'dd.MM.yyyy')}</div>
                                    <div><strong>Updated:</strong> {format(new Date(updated_at), 'dd.MM.yyyy')}</div>
                                    <div><strong>Pen model name:</strong> {penModel ? <Tag>{penModel?.name}</Tag> : 'No data'}</div>
                                    <div><strong>Pen model type:</strong> {penModel ? <Tag>{penModel?.type}</Tag> : 'No data'}</div>
                                </div>

                                <div className={styles.actions}>
                                    <Button
                                        size={ButtonSize.MD}
                                        palette={ButtonPalette.SECONDARY_GRAY}
                                        onClick={() => {
                                            const campaign = region.campaigns.find((el: any) => el.uuid === uuid);

                                            dispatch(addCampaignData({
                                                name: campaign.name,
                                                uuid
                                            }));
                                            navigate(`${pathname}/${uuid}`);
                                        }}>Assign workers</Button>
                                </div>

                            </div>;

                            return htmlStructure;
                        },

                        onExpand: (isRowExpanded, record) => {
                            if (isRowExpanded === true) {
                                setExpandedRow([record.key]);
                            } else {
                                setExpandedRow([]);
                            }
                        },
                        expandedRowKeys: expandedRow,
                    }}
                    dataSource={region.campaigns.map((el: any) => ({ ...el, key: el.uuid }))}
                />
            </div>}

            {!isLoading && region.campaigns?.length === 0 && <EmptyCardNoData message='No entries found' />}

        </BodyPage>

        {showModal && <AddCampaignModal refetch={refetch} onClose={() => setModalOpen(false)} />}
        {showModalDeleteRegion && <RemoveRegionModal region_uuid={regionUuid.uuid || param1} onClose={() => setModalDeleteRegionOpen(false)} />}

        <Drawer title={`Export for ${selectedCampaign?.name}`} subtitle='Email the export of properties for a campaign'>
            <div>
                <p className={classNames('text-sm', 'text-500', 'text-secondary', styles.title)}> Select type of export:</p>

                <Radio.Group onChange={onChange} value={value}>
                    <Radio value={TypeOfExport.DOOR_TO_DOOR}>Door to Door</Radio>
                    <Radio value={TypeOfExport.MARKETING}>Marketing</Radio>
                </Radio.Group>
            </div>

            {value === TypeOfExport.DOOR_TO_DOOR && <KnockListForm />}
            {value === TypeOfExport.MARKETING && <MarketingForm />}
        </Drawer>

        {isEditModalOpen.isOpen && <EditCampaignModal onClose={() => setEditModalOpen({
            isOpen: false,
            campaign_uuid: ''
        })} refetch={refetch} campaign_uuid={isEditModalOpen.campaign_uuid} />}

        {showModalAnalytic && <ModalAnalytic onClose={() => setModalAnalytic(false)} />}

    </>;
};