import format from 'date-fns/format';
import { ButtonGroup } from 'elements/ButtonGroup';
import { Chart } from 'elements/Chart';
import styles from './styles.module.scss';
import { useState } from 'react';
import { companiesAPI } from 'services/Companies/CompaniesService';
import { urlFormat } from 'utils/urlFormat';
import classNames from 'classnames';
import { TimePeriod, desiredDate } from './utils/getDesiredDate';
import { usersAPI } from 'services/Hercules/HerculesUsersService';

export const SalesOverview = () => {
    const [period, setPeriod] = useState(TimePeriod.y);
    const { data: currentUser = [], isLoading }: any = usersAPI.useGetCurrentUserQuery();

    const queries = {
        company_uuid: currentUser.company_uuid,

        params: {
            start: format(desiredDate(period), 'yyyy-MM-dd'),
            end: format(new Date(), 'yyyy-MM-dd'),
        }
    };

    const { data: companiesSalesData = [] } = companiesAPI.useGetSalesStatsQuery({
        company_uuid: queries.company_uuid,
        params: urlFormat(queries.params)
    }, {
        skip: isLoading
    });

    const config = [
        {
            key: TimePeriod.w,
            children: '7 days',
            onClick: () => setPeriod(TimePeriod.w),
        },
        {
            key: TimePeriod.m,
            children: '30 days',
            onClick: () => setPeriod(TimePeriod.m),
        },
        {
            key: TimePeriod.q,
            children: '3 month',
            onClick: () => setPeriod(TimePeriod.q),
        },
        {
            key: TimePeriod.y,
            children: '12 month',
            onClick: () => setPeriod(TimePeriod.y),
        },
        // {
        //     key: TimePeriod.custom,
        //     children: <>
        //         <Icons.Plus />
        //         Custom
        //     </>,
        //     onClick: () => setPeriod(TimePeriod.custom)
        // }
    ];

    const trace1 = {
        x: companiesSalesData.map((el: any) => el.date),
        y: companiesSalesData.map((el: any) => el.sales),
        fill: 'tozeroy',
        type: 'scatter',
        name: 'Sales',
        fillgradient: {
            color: 'rgba(54, 68, 241, 1)',
            colorscale: [
                ['0.0', 'rgba(54, 68, 241, 0)'],
                ['0.5', 'rgba(54, 68, 241, 0.3)'],
                ['1.0', 'rgba(54, 68, 241, 0.7)']
            ],
            type: 'vertical'
        },
        line: {
            color: 'rgba(54, 68, 241, 0.6)'
        }
    };

    const data = [trace1];

    return <>
        <div className={styles.salesOverview}>
            <h2 className={classNames('text-md', 'text-500', 'text-primary')}>Sales overview</h2>
            <ButtonGroup config={config} defaultValue={period} />
        </div>

        <Chart plotData={data} />
    </>;
};