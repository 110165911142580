import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Icons } from 'icons';
import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
    onChange(data: string[]): void;
    className?: any;
};

export const CSVReader: React.FC<Props> = ({ onChange, className }) => {
    const [filename, setFilename] = useState('Upload CSV');

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            try {
                const file = e.target.files[0];
                setFilename(e.target.files[0].name);
                // 1. create url from the file
                const fileUrl = URL.createObjectURL(file);

                // 2. use fetch API to read the file
                const response = await fetch(fileUrl);

                // 3. get the text from the response
                const text = await response.text();

                // 4. split the text by newline
                const lines = text.split('\n');

                // 5. map through all the lines and split each line by comma.
                const _data = lines.map((line) => line.split(',')[0]);

                const preparedData = _data.map((el) => {
                    const first = el.indexOf('"');
                    const last = el.lastIndexOf('"');

                    // return el.slice(first, last);
                    if (first !== -1 && last !== -1) {
                        return el.slice(first + 1, last);
                    }
                    return el;
                });

                onChange(preparedData);
            } catch (error) {
                console.error(error);
            }
        }
    };

    return <Button size={ButtonSize.SM}
        palette={ButtonPalette.SECONDARY_GRAY}
        className={className}>
        <Icons.Upload />
        {filename}
        <input className={styles.input} type='file' accept='.csv' onInput={handleFileChange} />
    </Button>;
};