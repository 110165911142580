import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Input } from 'elements/Input';
import { Icons } from 'icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectRegion } from 'store/reducers/regionSlice';
import { CreateRegionInputs, defaultValuesCampaign, validationSchema } from './FormValidation';
import styles from './styles.module.scss';
import { Select } from 'elements/Select';
import { penModelAPI } from 'services/PenModel';
import { PenModel } from 'models/PenModel';
import { Skeleton } from 'elements/Skeleton';

interface Props {
    onClose: () => void;
    refetch?: () => any;
}
export const AddCampaignModal: React.FC<Props> = ({ onClose, refetch = () => { } }) => {
    const { param1 } = useParams();
    // createRegion
    const [createCampaign, { error: createCampaignError, isSuccess, isError }] = campaignAPI.useCreateCampaignMutation();
    const { data: penModels = [], isLoading, isError: isPenModelError } = penModelAPI.useGetAllPenModelsQuery();
    const regionUuid = useSelector(selectRegion);

    const [penModelUuid, setPenModelUuid] = useState<string>();

    const options = penModels.map((el: PenModel) => {
        return {
            value: el.uuid,
            label: el.name
        };
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValuesCampaign,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: typeof defaultValuesCampaign) => {
        try {
            await createCampaign({
                region_uuid: regionUuid.uuid || param1,
                pen_model_uuid: penModelUuid,
                name: data.name,
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createCampaignError);
        }

    };

    useEffect(() => {
        // @ts-ignore
        if (isSuccess) {
            toast.success('New campaign has been successfully created!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isError || isPenModelError) {
            toast.error('An error occurred while creating campaign. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isError, isPenModelError]);

    watch(CreateRegionInputs.NAME);

    return <Modal onClose={() => onClose()}>
        <ModalHeader>
            <div className={styles.decoration}>
                <Icons.Point />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Add Campaign</h2>
                <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Set configurations for a new campaign in your region</p>
            </div>

        </ModalHeader>
        <ModalBody className={styles.modalGrid}>
            <Input
                className={styles.name}
                type='text'
                label='Name*'
                placeholder='Add your campaign name'
                error={{
                    message: errors.name?.message
                }}
                registerProps={
                    { ...register(CreateRegionInputs.NAME, { required: true }) }
                }
            />

            {isLoading && <Skeleton size={60} />}
            {!isLoading && <Select
                isBlock
                label='Pen model'
                data={options}
                width={{
                    width: '100%',
                    maxWidth: '100%'
                }}
                onChange={(value) => setPenModelUuid(value)} />}
        </ModalBody>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => onClose()}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                type='submit'
                onClick={handleSubmit(onSubmit)}>
                Add campaign
            </Button>
        </ModalFooter>
    </Modal>;
};