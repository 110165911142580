import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import styles from './style.module.scss';
import classNames from 'classnames';
import { Icons } from 'icons';
import React from 'react';

interface Props {
    title?: string;
    address?: string;
    horizontal?: boolean;
    onClickView?: () => void;
    onClickCampaigns?: () => void;
}

export const RegionCard: React.FC<Props> = ({ title, address, onClickView = () => { }, onClickCampaigns = () => { }, horizontal = false }) => {
    return <div className={classNames(styles.card, {
        [styles.horizontal]: horizontal
    })}>
        <div className={styles.cover}></div>
        <div className={styles.textBlock}>
            <h2 className={classNames(
                'text-primary', 'text-lg', 'text-500'
            )}>{title}</h2>
            {address && <p className={classNames(
                'text-tertiary', 'text-sm', 'text-400',
                styles.address
            )}>
                <Icons.Point />
                <span>{address}</span> </p>}
        </div>
        <div className={styles.buttons}>
            <Button
                onClick={() => onClickView()}
                size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_COLOR}
                className={classNames(styles.btn)}
            >View on map</Button>

            <Button
                onClick={() => onClickCampaigns()}
                size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                className={classNames(styles.btn)}
            >Campaigns</Button>
        </div>
    </div>;
};