import classNames from 'classnames';
import { Icons } from 'icons';
import React from 'react';
import { Negative } from './icons/Negative';
import { Positive } from './icons/Positive';
import styles from './styles.module.scss';
interface Props {
    title?: string;
    value?: string;
    growValue?: number;
}

export const StatisticTile: React.FC<Props> = ({
    title,
    value,
    growValue
}) => {
    return <div className={styles.tile}>
        <h2 className={classNames('text-md', 'text-500', 'text-primary', styles.title)}>{title}</h2>
        <p className={classNames('display-md', 'text-500', 'text-primary', styles.value)}>{value}</p>
        <p className={classNames('text-sm', 'text-500', 'text-tertiary', styles.grow)}>
            <span className={classNames(styles.growValue, {
                [styles.up]: growValue && growValue >= 0,
                [styles.down]: growValue && growValue < 0,
            })}>
                {growValue && growValue >= 0 && <>
                    <Icons.ArrowTop className={styles.icon} />
                    {growValue}%
                </>}

                {growValue && growValue < 0 && <><Icons.ArrowBottom className={styles.icon} />
                    {growValue}%
                </>}
            </span>

            vs last month
        </p>
        <div className={styles.illustration}>
            {growValue && growValue >= 0 && <Positive />}
            {growValue && growValue < 0 && <Negative />}
        </div>

    </div>;
};

export const Churn: React.FC<Props> = ({
    title,
    value,
    growValue
}) => {
    return <div className={styles.tile}>
        <h2 className={classNames('text-md', 'text-500', 'text-primary', styles.title)}>{title}</h2>
        <p className={classNames('display-md', 'text-500', 'text-primary', styles.value)}>{value}</p>
        <p className={classNames('text-sm', 'text-500', 'text-tertiary', styles.grow)}>
            <span className={classNames(styles.growValue, {
                [styles.down]: growValue && growValue >= 0,
                [styles.up]: growValue && growValue < 0,
            })}>
                {growValue && growValue >= 0 && <>
                    <Icons.ArrowTop className={styles.icon} />
                    {growValue}%
                </>}

                {growValue && growValue < 0 && <><Icons.ArrowBottom className={styles.icon} />
                    {growValue}%
                </>}
            </span>

            vs last month
        </p>
        <div className={styles.illustration}>
            {growValue && growValue >= 0 && <Negative.Up />}
            {growValue && growValue < 0 && <Positive.Bottom />}
        </div>

    </div>;
};

// StatisticTile.Churn = StatisticTile;