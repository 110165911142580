import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';

interface Props {
    name?: string;
    email?: string;
}
export const User: React.FC<Props> = ({ name = '', email }) => {
    return <div className={styles.userBadge}>
        <div className={styles.userAvatar}>{name[0]}</div>
        <div className={styles.userInfo}>
            <p className={classNames('text-secondary', 'text-sm', 'text-500')}>{name}</p>
            {email && <p className={classNames('text-tertiary', 'text-xs', 'text-400')}>{email}</p>}
        </div>
    </div>;
};