import { yupResolver } from '@hookform/resolvers/yup';
import { Switch } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Input } from 'elements/Input';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { KnockListInputs, defaultValuesKnockList, validationSchemaKnockList } from './FormValidation';
import styles from './styles.module.scss';

export const KnockListForm = () => {
    const campaignInfo = useSelector(selectCampaign);
    const [createExport, { error: createExportError, isLoading, isSuccess, isError }] = campaignRunAPI.useCreateCampaignRunExportMutation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValuesKnockList,
        resolver: yupResolver(validationSchemaKnockList),
    });

    const onSubmit = async (data: {
        chunk_count: number,
        min_property_count?: number | null;
    }) => {
        try {
            await createExport({
                body: {
                    campaign_uuid: campaignInfo.uuid,
                    name: `${campaignInfo.campaignName}_${Date.now()}`,
                    chunk_count: data.chunk_count,
                    min_property_count: data.min_property_count || 160
                }
            });
            // refetch();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, createExportError);
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (isSuccess) {
            toast.success('Email export request for selected campaign has been queued!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isError) {
            toast.error('An error occurred while generating knock list. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isError]);

    watch(KnockListInputs.MIN_COUNT);
    watch(KnockListInputs.NUMBER_SALES_PEOPLE);

    return <div className={styles.knockListFormGrid}>

        <Input
            type='number'
            label='Number of Sales People'
            placeholder='Type a number here'
            error={{
                message: errors.chunk_count?.message
            }}
            registerProps={
                { ...register(KnockListInputs.NUMBER_SALES_PEOPLE, { required: true }) }
            }
        />
        <Input
            type='number'
            label='Number of Doors Daily'
            placeholder='Type a number here'
            min={160}
            error={{
                message: errors.min_property_count?.message
            }}
            registerProps={
                { ...register(KnockListInputs.MIN_COUNT) }
            }
        />

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled defaultChecked onChange={() => { }} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include MDUs&#39;</p>
            <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial or residential building with multiple offices or apartments</span>
        </div>

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled onChange={() => { }} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include Businesses</p>
            <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial building</span>
        </div>
        <Button
            size={ButtonSize.MD}
            palette={ButtonPalette.PRIMARY}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}>Create knock list</Button>

    </div>;
};