import { ROUTES } from 'constants/routes';
import { BodyPage } from 'elements/BodyPage';
import { Breadcrumb } from 'elements/Breadcrumb';
import { HeaderPage } from 'elements/HeaderPage';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { closeDrawer } from 'store/reducers/drawerSlice';
import { selectRegion } from 'store/reducers/regionSlice';
import { Campaign } from './Campaign';
import { DigitalMarketing } from './DigitalMarketing';
import { Marketing } from './Marketing';
import styles from './styles.module.scss';

export enum RunStatus {
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED'
}
export const RunPage = () => {
    const { campaignName, } = useSelector(selectCampaign);
    const { name: regionName } = useSelector(selectRegion);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = campaignRunAPI.useGetAllRunsQuery();
    useEffect(() => {
        dispatch(closeDrawer());
    }, []);

    return (<>
        {isLoading && <Skeleton.TableCell size='l' />}
        {!isLoading &&
            <HeaderPage
                title={campaignName}
                subtitle='Please select sales for a churn in a campaign run'
                className={styles.headerGrid}
                withBreadcrumb
                breadcrumb={
                    <>
                        <Breadcrumb className={styles.breadcrumb} items={[
                            {
                                title: <Link to={ROUTES.ROOT}><Icons.Home /></Link>,
                            },
                            {
                                title: <Link to={ROUTES.ROOT}>Region</Link>,
                            },
                            {
                                title: <a onClick={() => navigate(-1)}>{regionName}</a>,
                            },
                            {
                                title: campaignName,
                            },
                        ]}
                        />
                    </>
                }>
            </HeaderPage >}

        <BodyPage className={styles.pageGrid}>
            <Campaign />
            <Marketing />
            <DigitalMarketing />
        </BodyPage>
    </>);
};;