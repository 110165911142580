import { Slider } from 'antd';
import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import format from 'date-fns/format';
import { Checkbox } from 'elements/Checkbox';
import { useAuth } from 'hooks/useAuth';
import mapboxGl from 'mapbox-gl';
import { Properties } from 'models/Campaigns';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { fitBoundToSelectedPolygon } from 'utils/fitBoundToSelectedPolygon';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styles from './Map.module.scss';

(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;

enum Colors {
    Red = '#FF0000',
    Orange = '#FF7F00',
    Yellow = '#FFFF00',
    YellowGreen = '#7FFF00',
    Green = '#00FF00',
    White = '#FFFFFF',
    Black = '#000000',
    Gray = '#727272',
    Transparent = 'transparent'
}
const floatToPercent = (x: any) => `${(x * 100).toFixed(3)}%`;
const host = process.env.REACT_APP_HERMES_API_URL;

export const RegionMap = ({ region, firstCampaign }: any) => {
    const { setDraw, setMap, map } = useMap();
    const { token } = useAuth();
    const campaign_uuid = useSelector(selectCampaign);

    const campaignUUID = campaign_uuid.uuidForMap || firstCampaign;

    const mapRef = useRef<HTMLDivElement>(null);

    const { data: properties = [], isFetching }: any = campaignAPI.useGetPropertiesQuery({
        campaign_uuid: campaignUUID,
    }, {
        skip: !campaignUUID
    });

    useEffect(() => {
        if (properties.length) {

            const sorted = [...properties].sort((a: any, b: any) => a.predicated_pen - b.predicated_pen);
            console.log('properties', sorted);
        }
    }, [properties]);

    const [sliderValue, setSliderValue] = useState<number[]>([0, 1]);
    const [isPenModelShow, setPenModelShow] = useState(true);
    const [isStreetWorksShow, setStreetWorkShow] = useState(false);
    const [isCityFibreShow, setCityFibreShow] = useState(false);
    const [isOpenReachPropShow, setOpenReachPropShow] = useState(false);

    useEffect(() => {
        if (!mapRef.current) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            center: [-1.748506, 52.380998],
            transformRequest: (url: any): any => {
                if (url.startsWith(host)) {
                    return {
                        url,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };
                }
            },
            style: {
                version: 8,
                sources: {
                    base: {
                        type: 'raster',
                        tiles: ['https://os.fibreplanner.io/z-{z}_x-{x}_y-{y}'],
                        maxzoom: 20,
                        tileSize: 256,
                    },
                },
                layers: [
                    {
                        id: 'base-layer',
                        type: 'raster',
                        source: 'base',
                        minzoom: 0,
                        maxzoom: 24,
                    },
                ],
            },
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            maxBounds: [-10.731977871337193, 49.59411301175666, 1.9010451201391163, 61.32814923895637],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });

        if (map.getSource('oa')) {
            map.removeLayer('oa-line-layer');
            map.removeLayer('oa-fill-layer');
            map.removeSource('oa');
        }

        if (map.getSource('property')) {
            map.removeLayer('property-circle-layer');
            map.removeSource('property');
        }

        map.on('load', async () => {
            if (!map.getLayer(`region_${region.uuid}`)) {
                map.addSource(`region_${region.uuid}_source`, {
                    'type': 'geojson',
                    'data': region.geometry
                });

                map.addLayer({
                    'id': `region_${region.uuid}`,
                    'type': 'fill',
                    'source': `region_${region.uuid}_source`,
                    // 'source-layer': 'region_${el.uuid}_source',
                    'layout': {},
                    'paint': {
                        'fill-color': '#0099FF',
                        'fill-opacity': 0.1
                    }
                });

            }
            setMap(map);
            fitBoundToSelectedPolygon(map, region.uuid);
        });

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, []);

    useEffect(() => {
        if (!map) return;
        if (!firstCampaign) return;

        const campaignId = campaign_uuid.uuidForMap || firstCampaign;

        if (map.getSource('oa')) {
            map.removeLayer('oa-line-layer');
            map.removeLayer('oa-fill-layer');
            map.removeSource('oa');
        }

        if (map.getSource('property')) {
            map.removeLayer('property-circle-layer');
            map.removeSource('property');
        }

        if (!map.getSource('oa')) {

            map.addSource('oa', {
                type: 'vector',
                tiles: [
                    `${host}/api/v1/campaigns/${campaignId}/properties/tiles/{z}/{x}/{y}.mvt?group=oa`,
                ],
                minzoom: 0,
                maxzoom: 15,
            });

            map.addLayer({
                id: 'oa-fill-layer',
                type: 'fill',
                source: 'oa',
                'source-layer': 'default',
                // temporary solution
                filter: ['all', ['>=', 'predicated_pen', 0.18]],
                paint: {
                    // temporary solution
                    'fill-color': [
                        'case',
                        ['<', ['get', 'predicated_pen'], 0.45],
                        Colors.Red,
                        ['all', ['>=', ['get', 'predicated_pen'], 0.45], ['<', ['get', 'predicated_pen'], 0.5]],
                        Colors.Orange,
                        ['all', ['>=', ['get', 'predicated_pen'], 0.5], ['<', ['get', 'predicated_pen'], 0.6]],
                        Colors.Yellow,
                        ['all', ['>=', ['get', 'predicated_pen'], 0.6], ['<', ['get', 'predicated_pen'], 0.7]],
                        Colors.YellowGreen,
                        ['>=', ['get', 'predicated_pen'], 0.7],
                        Colors.Green,
                        Colors.Transparent
                    ],
                    'fill-opacity': 0.5
                },
                minzoom: 3,
                maxzoom: 15,
            },);

            map.addLayer({
                id: 'oa-line-layer',
                type: 'line',
                source: 'oa',
                'source-layer': 'default',
                paint: {
                    'line-color': Colors.Gray,
                    'line-opacity': 0.1,
                    'line-width': 1,
                },
                minzoom: 3,
                maxzoom: 15,
            });

            map.addSource('property', {
                type: 'vector',
                tiles: [
                    `${host}/api/v1/campaigns/${campaignId}/properties/tiles/{z}/{x}/{y}.mvt`,
                ],
                minzoom: 15,
                maxzoom: 24,
            });

            map.addLayer({
                id: 'property-circle-layer',
                type: 'circle',
                source: 'property',
                'source-layer': 'default',
                paint: {
                    // temporary solution
                    'circle-color': [
                        'case',
                        ['<', ['get', 'predicated_pen'], 0.45],
                        Colors.Red,
                        ['all', ['>=', ['get', 'predicated_pen'], 0.45], ['<', ['get', 'predicated_pen'], 0.5]],
                        Colors.Orange,
                        ['all', ['>=', ['get', 'predicated_pen'], 0.5], ['<', ['get', 'predicated_pen'], 0.6]],
                        Colors.Yellow,
                        ['all', ['>=', ['get', 'predicated_pen'], 0.6], ['<', ['get', 'predicated_pen'], 0.7]],
                        Colors.YellowGreen,
                        ['>=', ['get', 'predicated_pen'], 0.7],
                        Colors.Green,
                        Colors.Transparent
                    ],
                    'circle-opacity': 0.9,
                    'circle-radius': {
                        'base': 1.75,
                        'stops': [
                            [12, 2],
                            [22, 90]
                        ]
                    },
                    'circle-stroke-width': 1,
                    'circle-stroke-color': 'black'
                },
                minzoom: 15,
                maxzoom: 24,
            });

        }

        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxGl.Popup({
            closeButton: false,
        });

        // property-circle-layer
        map.on('mouseenter', 'property-circle-layer', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('click', 'property-circle-layer', (e: any) => {
            // @ts-ignore
            const props = e.features[0].properties;

            const uprn = props!.uprn;
            const classification_code = props!.classification_code;
            const building_number = props!.building_number;
            const postcode = props!.postcode;
            const pen_model_type = props!.pen_model_type;
            const predicated_pen = props!.predicated_pen;

            const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
            const propsHtml = `
          <h2>Classification Code:<strong> ${classification_code}</strong></h2>
          <h2>Building Number: <strong>${building_number}</strong></h2>
          <h2>Postcode: <strong>${postcode}</strong></h2>
          <h2>Pen Model Type: <strong>${pen_model_type}</strong></h2>
          <h2>Predicated Pen: <strong>${floatToPercent(predicated_pen)}</strong></h2>
        `;
            const html = `<div class='popup'>
          ${titleHtml}
          ${propsHtml}
          </div>
        `;
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            // Copy coordinates array.
            // @ts-ignore
            const coordinates = e.features[0].geometry.coordinates.slice();

            popup.setLngLat(coordinates).setHTML(html).addTo(map);
        });

        // oa-fill-layer
        map.on('click', 'oa-fill-layer', (e) => {
            // @ts-ignore
            const props = e.features[0].properties;

            // @ts-ignore
            console.log(e.features[0]);

            const oa_code = props!.oa_code;
            const lsoa_code = props!.lsoa_code;
            const lsoa_name = props!.lsoa_name;
            const pen_model_type = props!.pen_model_type;
            const predicated_pen = props!.predicated_pen;

            const titleHtml = `<h1>OA: <strong>${oa_code}</strong></h1>`;
            const propsHtml = `
              <h2>LSOA: <strong>${lsoa_code}</strong></h2>
              <h2>LSOA Name: <strong>${lsoa_name}</strong></h2>
              <h2>Pen Model Type: <strong>${pen_model_type}</strong></h2>
              <h2>Predicated Pen: <strong>${floatToPercent(predicated_pen)}</strong></h2>
            `;
            const html = `<div class='popup'>
          ${titleHtml}
          ${propsHtml}
          </div>
        `;

            popup.setLngLat(e.lngLat)
                .setHTML(html)
                .addTo(map);
        });

        map.on('mouseenter', 'oa-fill-layer', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'oa-fill-layer', () => {
            map.getCanvas().style.cursor = '';
        });

    }, [map, campaign_uuid.uuidForMap, firstCampaign]);

    const [uprnCount, setUprnCount] = useState(0);

    useEffect(() => {
        if (!map) return;

        if (map.getLayer('property-circle-layer')) {
            map.setPaintProperty('property-circle-layer', 'circle-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                1,
                0]
            );
            map.setPaintProperty('property-circle-layer', 'circle-stroke-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                1,
                0]
            );
        }

        if (map.getLayer('oa-fill-layer')) {
            map.setPaintProperty('oa-fill-layer', 'fill-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                0.5,
                0]
            );
            map.setPaintProperty('oa-line-layer', 'line-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                0.5,
                0]
            );
        }

        const filter = properties.filter((el: Properties) => {
            return el.predicated_pen <= sliderValue[1] && el.predicated_pen >= sliderValue[0];
        });
        setUprnCount(filter.length);

    }, [map, sliderValue, properties]);

    useEffect(() => {
        if (!map) return;

        if (!map.getLayer('property-circle-layer') || !map.getLayer('oa-fill-layer')) return;

        if (!isPenModelShow) {
            map.setLayoutProperty('property-circle-layer', 'visibility', 'none');
            map.setLayoutProperty('oa-fill-layer', 'visibility', 'none');
            map.setLayoutProperty('oa-line-layer', 'visibility', 'none');
        } else {
            map.setLayoutProperty('property-circle-layer', 'visibility', 'visible');
            map.setLayoutProperty('oa-fill-layer', 'visibility', 'visible');
            map.setLayoutProperty('oa-line-layer', 'visibility', 'visible');

        }
    }, [map, isPenModelShow]);

    useEffect(() => {
        if (!map) return;
        if (!firstCampaign) return;

        const campaignId = campaign_uuid.uuidForMap || firstCampaign;

        if (map.getSource('streetworks')) {
            map.removeLayer('streetworks-layer');
            map.removeSource('streetworks');
        }

        if (!map.getSource('streetworks')) {
            map.addSource('streetworks', {
                type: 'vector',
                tiles: [
                    `${host}/api/v1/campaigns/${campaignId}/streetworks/tiles/{z}/{x}/{y}.mvt`,
                ],
                minzoom: 15,
                maxzoom: 24,
            });

            map.addLayer({
                id: 'streetworks-layer',
                type: 'circle',
                source: 'streetworks',
                'source-layer': 'default',
                paint: {
                    'circle-color': '#f00',
                    'circle-opacity': 0.7,
                    'circle-radius': {
                        'base': 1.5,
                        'stops': [
                            [12, 2],
                            [22, 90]
                        ]
                    },
                    'circle-stroke-width': 1,
                    'circle-stroke-color': 'black'
                },
                minzoom: 15,
                maxzoom: 24,
            });

            // pop up Streetworks
            map.on('click', 'streetworks-layer', (e) => {
                // @ts-ignore
                const props = e.features[0].properties!;

                const id = props.id;
                const promoter = props.promoter;
                const active_works = props.active_works;
                const works_desc = props.works_desc;
                const distance = props.distance;

                const titleHtml = `<h1>ID: <strong>${id}</strong></h1>`;
                const propsHtml = `
                    <h2>Promoter: <strong>${promoter}</strong></h2>
                    <h2>Is work still active? <strong>${active_works ? 'Yes' : 'No'}</strong></h2>
                    <h2>Description: <strong>${works_desc}</strong></h2>
                    <h2>Distance: <strong>${distance}</strong></h2>
                `;
                const html = `<div class='popup'>
                    ${titleHtml}
                    ${propsHtml}
                </div>`;

                new mapboxGl.Popup({ maxWidth: '400px' })
                    .setLngLat(e.lngLat)
                    .setHTML(html)
                    .addTo(map);
            });

            map.on('mouseenter', 'streetworks-layer', () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', 'streetworks-layer', () => {
                map.getCanvas().style.cursor = '';
            });
        }

        if (!isStreetWorksShow) {
            map.setLayoutProperty('streetworks-layer', 'visibility', 'none');
        } else {
            map.setLayoutProperty('streetworks-layer', 'visibility', 'visible');

        }

    }, [map, campaign_uuid.uuidForMap, firstCampaign, isStreetWorksShow]);

    useEffect(() => {
        if (!map) return;
        if (!firstCampaign) return;

        const campaignId = campaign_uuid.uuidForMap || firstCampaign;

        if (map.getSource('city-fibre-uprns')) {
            map.removeLayer('city-fibre-uprns-layer');
            map.removeSource('city-fibre-uprns');
        }

        if (!map.getSource('city-fibre-uprns')) {
            map.addSource('city-fibre-uprns', {
                type: 'vector',
                tiles: [
                    `${host}/api/v1/campaigns/${campaignId}/city-fibre-properties/tiles/{z}/{x}/{y}.mvt`,
                ],
                minzoom: 15,
                maxzoom: 24,
            });

            map.addLayer({
                id: 'city-fibre-uprns-layer',
                type: 'circle',
                source: 'city-fibre-uprns',
                'source-layer': 'default',
                paint: {
                    'circle-color': '#555555',
                    'circle-opacity': 0.8,
                    'circle-radius': {
                        'base': 1.9,
                        'stops': [
                            [12, 2],
                            [22, 90]
                        ]
                    },
                    'circle-stroke-width': 1,
                    'circle-stroke-color': 'black'
                },
                minzoom: 15,
                maxzoom: 24,
            });

            map.on('click', 'city-fibre-uprns-layer', (e) => {
                // @ts-ignore
                const props = e.features[0].properties!;

                const uprn = props.uprn;
                const rfs_date = props.rfs_date;
                const demand_point_type = props.demand_point_type;

                const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
                const propsHtml = `
                    <h2>RFS date: <strong>${format(new Date(rfs_date), 'dd.MM.yyyy')}</strong></h2>
                    <h2>Demand point type:<strong> ${demand_point_type}</strong></h2>
                  `;
                const html = `<div class='popup'>
                      ${titleHtml}
                      ${propsHtml}
                  </div>`;

                new mapboxGl.Popup({ maxWidth: '400px' })
                    .setLngLat(e.lngLat)
                    .setHTML(html)
                    .addTo(map);
            });

            map.on('mouseenter', 'city-fibre-uprns-layer', () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', 'city-fibre-uprns-layer', () => {
                map.getCanvas().style.cursor = '';
            });
        }

        if (!isCityFibreShow) {
            map.setLayoutProperty('city-fibre-uprns-layer', 'visibility', 'none');
        } else {
            map.setLayoutProperty('city-fibre-uprns-layer', 'visibility', 'visible');

        }

    }, [map, campaign_uuid.uuidForMap, firstCampaign, isCityFibreShow]);

    useEffect(() => {
        if (!map) return;
        if (!firstCampaign) return;

        const campaignId = campaign_uuid.uuidForMap || firstCampaign;

        if (map.getSource('openreach-properties')) {
            map.removeLayer('openreach-properties-layer');
            map.removeSource('openreach-properties');
        }

        if (!map.getSource('openreach-properties')) {
            map.addSource('openreach-properties', {
                type: 'vector',
                tiles: [
                    `${host}/api/v1/campaigns/${campaignId}/openreach-properties/tiles/{z}/{x}/{y}.mvt`,
                ],
                minzoom: 15,
                maxzoom: 24,
            });

            map.addLayer({
                id: 'openreach-properties-layer',
                type: 'circle',
                source: 'openreach-properties',
                'source-layer': 'default',
                paint: {
                    'circle-color': '#8000FF',
                    'circle-opacity': 0.7,
                    'circle-radius': {
                        'base': 1.75,
                        'stops': [
                            [12, 2],
                            [22, 90]
                        ]
                    },
                    'circle-stroke-width': 1,
                    'circle-stroke-color': 'black'
                },
                minzoom: 15,
                maxzoom: 24,
            });

            map.on('click', 'openreach-properties-layer', (e) => {
                // @ts-ignore
                const props = e.features[0].properties!;

                const uprn = props.uprn;
                const mdu = props.mdu;
                const parent_exchange_name = props.parent_exchange_name;
                const child_exchange_name = props.child_exchange_name;
                const prem_served = props.prem_served;
                const date_taken = props.date_taken;
                const category = props.category;
                const speeds = props.speeds;
                const interconnected_date = props.interconnected_date;

                const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
                const propsHtml = `
                    <h2>MDU: <strong> ${mdu}</strong></h2>
                    <h2>Parent exchange name: <strong>${parent_exchange_name}</strong></h2>
                    <h2>Child exchange name: <strong>${child_exchange_name}</strong></h2>
                    <h2>Prem served: <strong>${prem_served}</strong></h2>
                    <h2>Date taken: <strong>${date_taken}</strong></h2>
                    <h2>Category: <strong>${category}</strong></h2>
                    <h2>Speeds: <strong>${speeds}</strong></h2>
                    <h2>Interconnected_date:<strong> ${interconnected_date}</strong></h2>
                  `;
                const html = `<div class='popup'>
                        ${titleHtml}
                        ${propsHtml}
                    </div>`;

                new mapboxGl.Popup({ maxWidth: '400px' })
                    .setLngLat(e.lngLat)
                    .setHTML(html)
                    .addTo(map);
            });

            map.on('mouseenter', 'openreach-properties-layer', () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', 'openreach-properties-layer', () => {
                map.getCanvas().style.cursor = '';
            });
        }

        if (!isOpenReachPropShow) {
            map.setLayoutProperty('openreach-properties-layer', 'visibility', 'none');
        } else {
            map.setLayoutProperty('openreach-properties-layer', 'visibility', 'visible');

        }

    }, [map, campaign_uuid.uuidForMap, firstCampaign, isOpenReachPropShow]);

    const [isFullScreen, setFullScreen] = useState(false);
    useEffect(() => {
        if (!map) return;

        map.resize();

    }, [map, isFullScreen]);

    return (
        <>
            <div className={classNames(styles.mapWrapper, {
                [styles.backdrop]: isFullScreen
            })}>
                <div className={classNames(styles.modalView, {
                    [styles.fullScreen]: isFullScreen,
                    [styles.isModalOpen]: isFullScreen
                })}>
                    <div className={classNames(styles.mapContainer)} data-testid='Map'>
                        <div className={styles.map} id='map1' ref={mapRef} />

                        <Button size={ButtonSize.LG}
                            palette={ButtonPalette.SECONDARY_COLOR}
                            className={styles.btnFullScreen}
                            onClick={() => {
                                if (!isFullScreen) {
                                    setFullScreen(true);
                                } else {
                                    setFullScreen(false);
                                }
                            }}>
                            {!isFullScreen ? 'Full screen' : 'Close'}
                        </Button>
                        <div className={styles.props}>
                            <p className={classNames('text-sm', 'text-500', 'text-primary')}>Layers </p>
                            <Checkbox text='Sales Propensity Model' isChecked={isPenModelShow} onChange={() => setPenModelShow(prev => !prev)} />
                            <Checkbox text='Streetworks' isChecked={isStreetWorksShow} onChange={() => setStreetWorkShow(prev => !prev)} />
                            <Checkbox text='City Fibre UPRN' isChecked={isCityFibreShow} onChange={() => setCityFibreShow(prev => !prev)} />
                            <Checkbox text='Openreach UPRN' isChecked={isOpenReachPropShow} onChange={() => setOpenReachPropShow(prev => !prev)} />
                        </div>
                        <div className={styles.slider}>
                            <p className={classNames('text-sm', 'text-400', 'text-primary')}>Predicted pen
                                <span className={classNames('text-500', 'text-secondary')}>(UPRN shown: {uprnCount})</span>
                            </p>
                            <Slider range
                                defaultValue={sliderValue}
                                min={0}
                                max={1}
                                step={0.01}
                                onChange={(el) => {
                                    setSliderValue(el);
                                }}
                                disabled={isFetching}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};