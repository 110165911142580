import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './styles.module.scss';

interface Props {
    config: {
        key: string;
        children: any;
        onClick: () => void;
    }[];
    defaultValue?: string;
}
export const ButtonGroup: React.FC<Props> = ({ config, defaultValue }) => {
    const [isActive, setActive] = useState(defaultValue || config[0].key);

    const configJSX = config.map((el) => {
        return <button
            key={el.key}
            onClick={() => {
                setActive(el.key);
                el.onClick();
            }}
            className={classNames(styles.btnGrItem, {
                [styles.active]: el.key === isActive
            })}>
            {el.children}
        </button>;
    });

    return <>
        <div className={styles.buttonGroupContainer}>
            {configJSX}
        </div>
    </>;
};