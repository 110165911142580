import React, { useMemo, useState } from 'react';
import { ListBody } from './ListBody';
import { ListFooter } from './ListFooter';

interface Props {
    config: any;
    data: any;
    selectedCount?: number;
}
export const List: React.FC<Props> = ({ config, data, selectedCount = 8 }) => {
    // const selectedCount = 5;
    const [currentPage, setCurrentPage] = useState(1);

    return <>
        <ListBody
            config={config}
            data={
                useMemo(() => {
                    const firstPageIndex = (currentPage - 1) * selectedCount;
                    const lastPageIndex = firstPageIndex + selectedCount;

                    return data.slice(firstPageIndex, lastPageIndex);
                }, [currentPage, selectedCount, data])
            } />
        <ListFooter
            dataLength={data.length}
            selectedCount={selectedCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
        />
    </>;
};