import classNames from 'classnames';
import style from './style.module.scss';
import React from 'react';
interface Props {
    children: any;
    className?: string;
    subtitle?: string;
    title?: string;
    breadcrumb?: any;
    withBreadcrumb?: boolean;
}

export const HeaderPage: React.FC<Props> = ({ children, title, withBreadcrumb, breadcrumb, subtitle, className }) => {
    return (<>
        <div className={classNames(style.header, className)}>
            {withBreadcrumb && breadcrumb}
            <div className={style.titleInfo}>
                <h2 className={classNames('text-500', 'display-sm', 'text-primary')}>{title}</h2>
                <p className={classNames('text-400', 'text-md', 'text-tertiary')}>{subtitle}</p>
            </div>
            {children}
        </div>
    </>);
};