import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Icons } from 'icons';
import mapboxGl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styles from './styles.module.scss';

(mapboxGl as any).workerClass = MapboxWorker;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;
interface Props {
    onClose: () => void;
    setPolygon: (props: any) => void;
}

export const DrawRegionModal: React.FC<Props> = ({ onClose, setPolygon }) => {
    const { setDraw, setMap } = useMap();
    const mapRef = useRef<HTMLDivElement>(null);

    const updateArea = (e: any) => {
        if (e.features.length > 0) {
            setPolygon(e.features[0].geometry);
        }
        else if (e.type === 'draw.delete') {
            console.log('Click the map to draw a polygon.');
        }
    };
    useEffect(() => {
        if (!mapRef.current) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
                polygon: true,
                trash: true
            },
            // Set mapbox-gl-draw to draw by default.
            // The user does not have to click the polygon control button first.
            defaultMode: 'draw_polygon'
        });
        map.addControl(draw);
        map.addControl(new mapboxGl.FullscreenControl());

        map.on('load', async () => {
            setMap(map);
        });
        map.on('draw.create', updateArea);
        map.on('draw.delete', updateArea);
        map.on('draw.update', updateArea);

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, []);

    return <Modal onClose={() => onClose()}>
        <ModalHeader>
            <div className={styles.decoration}>
                <Icons.Point />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Draw Region</h2>
                {/* <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Set configurations for a new region</p> */}
            </div>

        </ModalHeader>
        <ModalBody className={styles.drawMap}>
            <div className={styles.map} id='map' ref={mapRef} />
        </ModalBody>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => {
                    onClose();
                    setPolygon(null);
                }}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                onClick={() => onClose()}
            >
                Draw region
            </Button>
        </ModalFooter>
    </Modal>;
};