import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Input } from 'elements/Input';
import { Icons } from 'icons';
import React, { useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import styles from './styles.module.scss';
import { Select } from 'elements/Select';
import { penModelAPI } from 'services/PenModel';
import { PenModel } from 'models/PenModel';
import { Skeleton } from 'elements/Skeleton';

interface Props {
    onClose: () => void;
    refetch?: () => any;
    campaign_uuid: string;
}
export const EditCampaignModal: React.FC<Props> = ({ campaign_uuid, onClose, refetch = () => { } }) => {
    const [updateCampaign, { error: updateCampaignError, isSuccess, isError }] = campaignAPI.useUpdateCampaignMutation();
    const { data: penModels = [], isLoading, isError: isPenModelError } = penModelAPI.useGetAllPenModelsQuery();
    const { data: campaign, isLoading: isCampaignLoading } = campaignAPI.useGetCampaignQuery({
        campaign_uuid
    }, {
        refetchOnMountOrArgChange: true
    });

    const options = penModels.map((el: PenModel) => {
        return {
            value: el.uuid,
            label: el.name
        };
    });

    const [penModelUuid, setPenModelUuid] = useState<string>(options.find((el) => el.value === campaign?.pen_model_uuid)?.value || '');

    const [campaignName, setName] = useState(campaign?.name || '');

    useEffect(() => {
        if (campaign?.name) {
            setName(campaign?.name);
        }
    }, [isCampaignLoading]);

    const onSubmit = async () => {
        console.log(!campaignName, !penModelUuid);
        if (!campaignName) return;

        try {
            await updateCampaign({
                campaign_uuid,
                body: {
                    pen_model_uuid: penModelUuid,
                    name: campaignName,
                }
            });
            refetch();
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error, updateCampaignError);
        }

    };

    useEffect(() => {
        // @ts-ignore
        if (isSuccess) {
            toast.success('Campaign has been successfully updated!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isError || isPenModelError) {
            toast.error('An error occurred while updating campaign. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isError, isPenModelError]);

    // watch(CreateRegionInputs.NAME);

    return <Modal onClose={() => onClose()}>
        <ModalHeader>
            <div className={styles.decoration}>
                <Icons.Point />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Edit Campaign</h2>
                <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Edit configurations for a campaign in your region</p>
            </div>

        </ModalHeader>
        <ModalBody className={styles.modalGrid}>
            {isCampaignLoading && <Skeleton size={60} />}
            {!isCampaignLoading && <Input
                className={styles.name}
                type='text'
                label='Name'
                placeholder='Add your campaign name'
                value={campaignName}
                onChange={(e: any) => {
                    setName(e.target.value);
                    // setValue(CreateRegionInputs.NAME, e.target.value);
                }}
            />}

            {isLoading && <Skeleton size={60} />}
            {!isLoading && <Select
                isBlock
                label='Pen model'
                data={options}
                width={{
                    width: '100%',
                    maxWidth: '100%'
                }}
                value={options.find((el) => el.value === campaign?.pen_model_uuid)?.value}
                onChange={(value) => setPenModelUuid(value)} />}
        </ModalBody>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => onClose()}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                type='submit'
                onClick={() => onSubmit()}>
                Edit campaign
            </Button>
        </ModalFooter>
    </Modal>;
};