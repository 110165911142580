import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface Props {
    url: string;
    text: string;
    icon?: any;
}

export const SSOLink: React.FC<Props> = ({ url, text, icon }) => {
    return <Link to={url} className={styles.link}>
        {icon}{text}
    </Link>;
};