import { yupResolver } from '@hookform/resolvers/yup';
import { Switch } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Input } from 'elements/Input';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { campaignDigitalMarketingRunAPI } from 'services/Campaigns/CampaignsDigitalMarketingRunService';
import { campaignMarketingRunAPI } from 'services/Campaigns/CampaignsMarketingRunService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { MarketingInputs, defaultValuesMarketing, validationSchemaMarketing } from './FormValidation';
import styles from './styles.module.scss';

export const MarketingForm = () => {
    const campaignInfo = useSelector(selectCampaign);
    const [isDigitalMarketing, setDigitalMarketing] = useState(false);
    const [createMarketingExport, { isLoading: isMarketingLoading, isSuccess: isMarketingSuccess, isError: isMarketingError }] = campaignMarketingRunAPI.useCreateCampaignMarketingRunExportMutation();
    const [createDigitalMarketingExport, { isLoading: isDigitalMarketingLoading, isSuccess: isDigitalMarketingSuccess, isError: isDigitalMarketingError }] = campaignDigitalMarketingRunAPI.useCreateCampaignDigitalMarketingRunExportMutation();

    const onChange = (checked: boolean) => {
        setDigitalMarketing(checked);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValuesMarketing,
        resolver: yupResolver(validationSchemaMarketing),
    });

    const onSubmit = async (data: {
        max_property_count: number,
    }) => {
        try {
            // 
            if (isDigitalMarketing) {
                await createDigitalMarketingExport({
                    body: {
                        campaign_uuid: campaignInfo.uuid,
                        name: `${campaignInfo.campaignName}_${Date.now()}`,
                        max_property_count: data.max_property_count,
                    }
                });
            } else {
                await createMarketingExport({
                    body: {
                        campaign_uuid: campaignInfo.uuid,
                        name: `${campaignInfo.campaignName}_${Date.now()}`,
                        max_property_count: data.max_property_count,
                    }
                });
            }
            // refetch();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error);
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (isMarketingSuccess) {
            toast.success('Marketing email export request for selected campaign has been queued!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isMarketingSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isMarketingError) {
            toast.error('An error occurred while generating marketing export. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isMarketingError]);

    useEffect(() => {
        // @ts-ignore
        if (isDigitalMarketingSuccess) {
            toast.success('Digital marketing email export request for selected campaign has been queued!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDigitalMarketingSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isDigitalMarketingError) {
            toast.error('An error occurred while generating digital marketing export. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isDigitalMarketingError]);

    watch(MarketingInputs.max_property_count);

    return <div className={styles.knockListFormGrid}>

        <Input
            type='number'
            label='Number of Addresses'
            placeholder='Type a number here'
            error={{
                message: errors.max_property_count?.message
            }}
            registerProps={
                { ...register(MarketingInputs.max_property_count, { required: true }) }
            }
        />

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled defaultChecked onChange={() => { }} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include MDUs&#39;</p>
            <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial or residential building with multiple offices or apartments</span>
        </div>

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled onChange={() => { }} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include Businesses</p>
            <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial building</span>
        </div>

        <div className={styles.switch}>
            <Switch value={isDigitalMarketing} onChange={onChange} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Digital marketing</p>
        </div>

        <Button
            size={ButtonSize.MD}
            palette={ButtonPalette.PRIMARY}
            isLoading={isMarketingLoading || isDigitalMarketingLoading}
            onClick={handleSubmit(onSubmit)}>Create export</Button>

    </div>;
};