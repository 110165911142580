// @ts-nocheck
export const Negative = () => {
    return <><svg width="130" height="66" viewBox="0 0 130 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4992_376491" maskUnits="userSpaceOnUse" x="1" y="1" width="128" height="64">
            <rect width="128" height="64" transform="translate(1 1)" fill="url(#paint0_linear_4992_376491)" />
        </mask>
        <g mask="url(#mask0_4992_376491)">
            <path opacity="0.2" d="M125.324 61.5642L129 65H1V1C1.59414 2.90879 2.827 12.3174 3.00523 12.3174C3.18348 12.3174 4.34206 4.8236 4.89906 5.96887L9.68931 9.4047L12.4743 23.7702L16.9304 12.2002L19.2698 8.76441L22.6118 23.7702H26.0653L28.9617 15.7533C30.0757 14.608 32.3483 12.3174 32.5266 12.3174C32.7048 12.3174 34.1604 10.0269 34.866 8.88162H36.6484L40.8816 16.5107L44.5579 15.7533L46.2289 23.7702L51.242 27.206L53.8042 30.6418L56.7006 36.3682L59.4856 30.5427H60.7111L64.0531 38.289L67.2837 36.3682L70.0688 30.6418H71.7398L74.6362 38.3881L78.7581 30.6418L82.6571 25.0507L86.5561 36.3682L90.4552 57.2175L93.3516 50.1115L96.4708 53.5473L100.704 50.1115L107.388 56.9831L110.507 53.5473L114.741 61.5642L118.305 56.9831L121.87 61.5642H125.324Z" fill="#D92D20" />
        </g>
        <path d="M129 65L125.324 61.5642H121.87L118.305 56.9831L114.741 61.5642L110.507 53.5473L107.388 56.9831L100.704 50.1115L96.4708 53.5473L93.3516 50.1115L90.4552 57.2175L86.5561 36.3682L82.6571 25.0507L78.7581 30.6418L74.6362 38.3881L71.7398 30.6418H70.0688L67.2837 36.3682L64.0531 38.289L60.7111 30.5427H59.4856L56.7006 36.3682L53.8042 30.6418L51.242 27.206L46.2289 23.7702L44.5579 15.7533L40.8816 16.5107L36.6484 8.88162H34.866C34.1604 10.0269 32.7048 12.3174 32.5266 12.3174C32.3483 12.3174 30.0757 14.608 28.9617 15.7533L26.0653 23.7702H22.6118L19.2698 8.76441L16.9304 12.2002L12.4743 23.7702L9.68931 9.4047L4.89906 5.96887C4.34206 4.8236 3.18348 12.3174 3.00523 12.3174C2.827 12.3174 1.59414 2.90879 1 1" stroke="#F04438" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.2">
            <rect x="72" y="18" width="20" height="20" rx="10" stroke="#F04438" strokeWidth="2" />
        </g>
        <rect x="77" y="23" width="10" height="10" rx="5" fill="white" />
        <rect x="77" y="23" width="10" height="10" rx="5" stroke="#F04438" strokeWidth="2" />
        <defs>
            <linearGradient id="paint0_linear_4992_376491" x1="64" y1="0" x2="64" y2="64" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="1" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
    </>;
};
// @ts-nocheck
const UP = () => {
    return <><svg width="130" height="66" viewBox="0 0 130 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_17488_11480" maskUnits="userSpaceOnUse" x="1" y="1" width="128" height="64">
            <rect width="128" height="64" transform="matrix(-1 0 0 1 129 1)" fill="url(#paint0_linear_17488_11480)" />
        </mask>
        <g mask="url(#mask0_17488_11480)">
            <path opacity="0.2" d="M4.67624 61.5642L1 65H129V1C128.406 2.90879 127.173 12.3174 126.995 12.3174C126.817 12.3174 125.658 4.8236 125.101 5.96887L120.311 9.4047L117.526 23.7702L113.07 12.2002L110.73 8.76441L107.388 23.7702H103.935L101.038 15.7533C99.9243 14.608 97.6517 12.3174 97.4734 12.3174C97.2952 12.3174 95.8396 10.0269 95.134 8.88162H93.3516L89.1184 16.5107L85.4421 15.7533L83.7711 23.7702L78.758 27.206L76.1958 30.6418L73.2994 36.3682L70.5144 30.5427H69.2889L65.9469 38.289L62.7163 36.3682L59.9312 30.6418H58.2602L55.3638 38.3881L51.2419 30.6418L47.3429 25.0507L43.4439 36.3682L39.5448 57.2175L36.6484 50.1115L33.5292 53.5473L29.2959 50.1115L22.6118 56.9831L19.4926 53.5473L15.2594 61.5642L11.6945 56.9831L8.12968 61.5642H4.67624Z" fill="#D92D20" />
        </g>
        <path d="M1 65L4.67624 61.5642H8.12968L11.6945 56.9831L15.2594 61.5642L19.4926 53.5473L22.6118 56.9831L29.2959 50.1115L33.5292 53.5473L36.6484 50.1115L39.5448 57.2175L43.4439 36.3682L47.3429 25.0507L51.2419 30.6418L55.3638 38.3881L58.2602 30.6418H59.9312L62.7163 36.3682L65.9469 38.289L69.2889 30.5427H70.5144L73.2994 36.3682L76.1958 30.6418L78.758 27.206L83.7711 23.7702L85.4421 15.7533L89.1184 16.5107L93.3516 8.88162H95.134C95.8396 10.0269 97.2952 12.3174 97.4734 12.3174C97.6517 12.3174 99.9243 14.608 101.038 15.7533L103.935 23.7702H107.388L110.73 8.76441L113.07 12.2002L117.526 23.7702L120.311 9.4047L125.101 5.96887C125.658 4.8236 126.817 12.3174 126.995 12.3174C127.173 12.3174 128.406 2.90879 129 1" stroke="#F04438" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <g opacity="0.2">
            <rect x="-1" y="1" width="20" height="20" rx="10" transform="matrix(-1 0 0 1 57 17)" stroke="#F04438" strokeWidth="2" />
        </g>
        <rect x="-1" y="1" width="10" height="10" rx="5" transform="matrix(-1 0 0 1 52 22)" fill="white" />
        <rect x="-1" y="1" width="10" height="10" rx="5" transform="matrix(-1 0 0 1 52 22)" stroke="#F04438" strokeWidth="2" />
        <defs>
            <linearGradient id="paint0_linear_17488_11480" x1="64" y1="0" x2="64" y2="64" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="1" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>

    </>;
};

Negative.Up = UP;