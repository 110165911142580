import { createSlice } from '@reduxjs/toolkit';

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        uuid: null,
        campaignName: '',
        uuidForMap: null
    },
    reducers: {
        addCampaignData: (state, action) => {
            state.uuid = action.payload.uuid;
            state.campaignName = action.payload.name;
        },
        removeCampaignData: (state) => {
            state.uuid = null;
            state.campaignName = '';
        },
        addMapCampaignUuid: (state, action) => {
            state.uuidForMap = action.payload.uuid;
        },
        removeMapCampaignUuid: (state) => {
            state.uuidForMap = null;
        }
    }
});

export const selectCampaign = (state: any) => state.campaign;

export const {
    addCampaignData,
    removeCampaignData,
    addMapCampaignUuid,
    removeMapCampaignUuid
} = campaignSlice.actions;

export default campaignSlice.reducer;
