import * as yup from 'yup';

export enum CreateRegionInputs {
    POSTCODES = 'postcodes',
    UPRNs = 'uprns',
    NAME = 'name'
}

export const validationSchema = yup
    .object({
        postcodes: yup.array().notRequired(),
        uprns: yup.array().notRequired(),
        name: yup.string().required('This field is required')
    });
// .required();

export const defaultValues: {
    postcodes: string[] | undefined,
    uprns: string[] | undefined,
    name: string;
} = {
    postcodes: [''],
    uprns: [''],
    name: ''
};