import { createSlice } from '@reduxjs/toolkit';

const leadSlice = createSlice({
    name: 'lead',
    initialState: {
        uprn: '',
        postcode: ''
    },
    reducers: {
        addUprn: (state, action) => {
            state.uprn = action.payload.uprn;
        },
        deleteUprn: (state) => {
            state.uprn = '';
        },
        addPostcode: (state, action) => {
            state.postcode = action.payload.postcode;
        },
        deletePostcode: (state) => {
            state.postcode = '';
        },
    }
});

export const selectLead = (state: any) => state.lead;

export const {
    addUprn,
    deleteUprn,
    addPostcode,
    deletePostcode
} = leadSlice.actions;

export default leadSlice.reducer;
