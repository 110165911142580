import classNames from 'classnames';
import styles from './styles.module.scss';

export const ChartTemplate = ({ children, title }: any) => {
    return <div className={styles.chartTemplate}>
        <div className={styles.chartHeader}>
            <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>{title}</h2>
        </div>
        {children}
    </div>;
};