import { Table, Tag } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignMarketingRunAPI } from 'services/Campaigns/CampaignsMarketingRunService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { RunStatus } from '..';

export const nameFormatter = (name: string) => {
    const nameSplit = name.split('_');
    console.log(nameSplit);
    if (nameSplit.length === 1) {
        return name;
    } else {
        const timeStamp = new Date(+nameSplit[nameSplit.length - 1]);
        nameSplit.pop();
        const date = `${nameSplit.join('_')} - ${timeStamp.getDate() <= 9 ? '0' + timeStamp.getDate() : timeStamp.getDate()}.${timeStamp.getMonth() + 1 <= 9 ? '0' + (timeStamp.getMonth() + 1) : (timeStamp.getMonth() + 1)}.${timeStamp.getFullYear()}`;

        return date;
    }
};

export const Marketing = () => {
    const { uuid: campaignUuid } = useSelector(selectCampaign);

    const { data: runs = [], isLoading } = campaignMarketingRunAPI.useGetAllMarketingRunsQuery();
    const [runsInCampaign, setRunsInCampaign] = useState([]);

    useEffect(() => {
        if (runs.length) {
            const arrayOfRuns = runs.filter((el: any) => el.campaign_uuid === campaignUuid);
            setRunsInCampaign(arrayOfRuns);
        }

    }, [isLoading]);

    const columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (name: any) => {
                return nameFormatter(name);
            },
        },
        { title: 'Property count', dataIndex: 'max_property_count', },
        {
            title: 'Active', dataIndex: 'is_active',
            render: (is_active: any) => is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,

        },
        {
            title: 'Status', dataIndex: 'status', render: (status: any) => {

                switch (status) {
                    case RunStatus.SUCCESS:
                        return <Tag color='green'>{RunStatus.SUCCESS}</Tag>;
                    case RunStatus.FAILED:
                        return <Tag color='red'>{RunStatus.FAILED}</Tag>;
                    case RunStatus.RUNNING:
                        return <Tag color='yellow'>{RunStatus.RUNNING}</Tag>;
                    case RunStatus.PENDING:
                        return <Tag color='blue'>{RunStatus.PENDING}</Tag>;
                }
            },
        },
    ];
    return <>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Marketing runs</h2>

        <Table
            columns={columns}
            pagination={false}
            dataSource={runsInCampaign}
        />
    </>;
};