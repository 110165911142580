import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { hermesAPI } from 'services/Hermes/HermesService';
import { tokenAPI } from 'services/Token';
import { campaignDigitalMarketingRunAPI } from 'services/Campaigns/CampaignsDigitalMarketingRunService';
import { campaignMarketingRunAPI } from 'services/Campaigns/CampaignsMarketingRunService';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { companiesAPI } from 'services/Companies/CompaniesService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { regionsAPI } from 'services/Regions/RegionsService';
import campaignSlice from './reducers/campaignSlice';
import drawerSlice from './reducers/drawerSlice';
import hermesSlice from './reducers/hermesSlice';
import regionSlice from './reducers/regionSlice';
import tokenSlice from './reducers/tokenSlice';
import { leadsAPI } from 'services/Leads/LeadsService';
import leadSlice from './reducers/leadSlice';
import { penModelAPI } from 'services/PenModel';

const rootReducer = combineReducers({
    [tokenAPI.reducerPath]: tokenAPI.reducer,
    token: tokenSlice,
    hermes: hermesSlice,
    [hermesAPI.reducerPath]: hermesAPI.reducer,
    drawer: drawerSlice,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [regionsAPI.reducerPath]: regionsAPI.reducer,
    region: regionSlice,
    [campaignAPI.reducerPath]: campaignAPI.reducer,
    campaign: campaignSlice,
    [companiesAPI.reducerPath]: companiesAPI.reducer,
    [campaignRunAPI.reducerPath]: campaignRunAPI.reducer,
    [campaignMarketingRunAPI.reducerPath]: campaignMarketingRunAPI.reducer,
    [campaignDigitalMarketingRunAPI.reducerPath]: campaignDigitalMarketingRunAPI.reducer,
    [leadsAPI.reducerPath]: leadsAPI.reducer,
    lead: leadSlice,
    [penModelAPI.reducerPath]: penModelAPI.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(
                tokenAPI.middleware,
                hermesAPI.middleware,
                usersAPI.middleware,
                regionsAPI.middleware,
                campaignAPI.middleware,
                companiesAPI.middleware,
                campaignRunAPI.middleware,
                campaignMarketingRunAPI.middleware,
                campaignDigitalMarketingRunAPI.middleware,
                leadsAPI.middleware,
                penModelAPI.middleware,
            ),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
