import { Cascader, DatePicker } from 'antd';
import { Chart } from 'elements/Chart';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { Tabs } from 'elements/Tabs';
import { useState } from 'react';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { regionsAPI } from 'services/Regions/RegionsService';
import { urlFormat } from 'utils/urlFormat';
import { ChartTemplate } from './ChartTemplate/ChartTemplate';
import styles from './styles.module.scss';

const { RangePicker } = DatePicker;

export const ChurnAnalisys = () => {
    const [regionUUid, setRegionUuid] = useState('');
    const [campaignsUuid, setCampaignsUuid] = useState('');
    const [regionsDates, setRegionDates] = useState<any>([]);
    const [campaignsDates, setCampaignsDates] = useState<any>([]);
    const [tab, setActiveTab] = useState('office');

    const { data: regions = [], isLoading: isRegionsLoading }: any = regionsAPI.useGetAllRegionsQuery();
    const regionOptions = regions.map((el: any) => {
        return {
            value: el.uuid,
            label: el.name
        };
    });

    const regionsQueries = {
        region_uuid: regionUUid,

        params: {
            start: regionsDates[0],
            end: regionsDates[1],
        }
    };

    const campaignsQueries = {
        campaign_uuid: campaignsUuid,

        params: {
            start: campaignsDates[0],
            end: campaignsDates[1],
        }
    };

    const { data: regionsSalesData = [], isLoading: isSalesLoading } = regionsAPI.useGetChurnStatsQuery({
        region_uuid: regionsQueries.region_uuid,
        params: urlFormat(regionsQueries.params)
    }, {
        skip: isRegionsLoading || regionUUid.length === 0 || regionsDates.length === 0
    });

    const { data: campaignsSalesData = [], isLoading: isCampaignsSalesLoading } = campaignAPI.useGetChurnStatsQuery({
        campaign_uuid: campaignsQueries.campaign_uuid,
        params: urlFormat(campaignsQueries.params)
    }, {
        skip: isRegionsLoading || !campaignsUuid || campaignsUuid?.length === 0 || campaignsDates.length === 0
    });

    const tabConfig = [
        {
            key: 'office',
            children: 'Office',
            onClick: () => setActiveTab('office')
        },
        {
            key: 'campaign',
            children: 'Campaign',
            onClick: () => setActiveTab('campaign')
        },
    ];

    const chartRegionData = [{
        x: regionsSalesData.map((el: any) => el.date),
        y: regionsSalesData.map((el: any) => el.churn),
        name: 'PIC',
        type: 'bar',
        marker: {
            color: '#242EDD',
        }
    }];

    const chartCampaignData = [{
        x: campaignsSalesData.map((el: any) => el.date),
        y: campaignsSalesData.map((el: any) => el.churn),
        name: 'PIC',
        type: 'bar',
        marker: {
            color: '#242EDD',
        }
    }];

    const campaignOptions = regions.map((el: any) => {
        const obj: any = {
            value: el.uuid,
            label: el.name,

        };

        if (Array.isArray(el.campaigns) && el.campaigns?.length) {
            const campaigns = el.campaigns.map((item: any) => {
                return {
                    value: item.uuid,
                    label: item.name,
                };
            });
            obj.children = campaigns;
        } else {
            obj.disabled = true;
        }

        return obj;
    });

    return <ChartTemplate title="PIC Analysis">
        <Tabs config={tabConfig} />

        {tab === 'office' && <div className={styles.chartCardLayout}>
            <RangePicker
                className={styles.rangePicker}
                onChange={(_, datestring) => {
                    setRegionDates(datestring);
                }} />
            <Select
                className={styles.select}
                data={regionOptions}
                onChange={(data) => setRegionUuid(data)}
                width={{ maxWidth: '100%', width: '100%' }}
            />

            <div className={styles.chartSection}>
                {isSalesLoading || regionsSalesData.length === 0 && <Skeleton.ChartPlaceholder />}
                {!isSalesLoading && regionsSalesData.length !== 0 && <Chart style={{
                    minHeight: '350px'
                }} plotData={chartRegionData} barmode='stack' />}
            </div>
        </div>}

        {tab !== 'office' && <div className={styles.chartCardLayout}>
            <RangePicker
                className={styles.rangePicker}
                onChange={(_, datestring) => {
                    setCampaignsDates(datestring);
                }} />
            <Cascader
                className={styles.cascader}
                placeholder='Select campaign'
                options={campaignOptions}
                expandTrigger="hover"
                onChange={(data: any) => {
                    if (data.length === 1) {
                        return;
                    }
                    setCampaignsUuid(data[1]);
                }}
            />

            <div className={styles.chartSection}>
                {isCampaignsSalesLoading || campaignsSalesData.length === 0 && <Skeleton.ChartPlaceholder />}
                {!isCampaignsSalesLoading && campaignsSalesData.length !== 0 && <Chart style={{
                    minHeight: '350px'
                }} plotData={chartCampaignData} barmode='stack' />}
            </div>

        </div>}

    </ChartTemplate>;
};